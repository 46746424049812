import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useLazyQuery } from '@apollo/client';
import {
  Spinner,
  TabSet,
  StatusBadge,
  Alert,
} from '@gsa/afp-component-library';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import Breadcrumbs from './widgets/Breadcrumbs';
import { RequisitionStatus } from './RequisitionDetailsUtils';
import {
  GET_REQUISITION_CART,
  GET_ACTIVE_CONTRACTS,
  GET_OPTIONAL_REQ,
  GET_CALCULATED_PRICE,
  GET_REQ_ATTACHMENT_TYPES,
} from '../../services/data-layer';
import groupOptionsPerCatalog from '../VehicleRequisition/utils/VehicleRequisitionUtils';
import { useQueryParam } from '../../hooks/useQueryParam';
import './RequisitionDetails.scss';
import ReviewSummaryTab from './tabs/ReviewSummaryTab';
import RequisitionActivitiesTab from './tabs/RequisitionActivitiesTab';
import UrgentRequirementReviewSummaryTab from './tabs/UrgentRequirementReviewSummary';
import VehicleRequisitionContextProvider from '../../Providers/VehicleRequisitionContextProvider/VehicleRequisitionContextProvider';
import ReturnOrderModal from './ReturnOrderModal';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';
import { RequisitionStatusType } from '../my-requisitions/tableHelpers';
import { GET_STANDARD_ITEM_BY_ID } from '../../services/data-layer/standards';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { RequisitionBoacErrors } from '../VehicleRequisition/constants/VehicleRequisitionErrors';
import { STEPS } from '../VehicleRequisition/constants/VehicleRequisitionConstants';
import ErrorContext from '../../context/ErrorContext/ErrorContext';
import AttachmentTable from './Attachment/AttachmentTable/AttachmentTable';
import SelectedOptions from '../VehicleRequisition/components/SelectedOptions/SelectedOptions';
import {
  REQUISITION_TYPE,
  isAreqType,
} from '../non-standard-purchase/constants';
import { StoreOperations, StoreSubjects } from '../../constants/constants';
import { setSessionStorageItems } from '../../utilities/commonUtils';
import { ValidationModal } from './ValidationModal/ValidationModal';
import { withoutAREQOption } from '../../constants/utils';
import { OPTION_QUANTITY_REQUIRED_CODE } from '../../utilities/CompareVehicleUtils';

const RequisitionDetails = () => {
  const query = useQueryParam();
  const requisitionId = query.get('requisitionId');
  const currentTab = query.get('tab');
  const [tab, setTab] = useState('summary');
  const [viewSelectedOptions, setViewSelectedOptions] = useState(false);
  const [requisitionContract, setRequisitionContract] = useState([]);
  const [requisitionCartState, setRequisitionCartState] = useState({});
  const [currentModLineIdentifier, setCurrentModLineIdentifier] = useState('');
  const [calculatedPriceInfoState, setCalculatedPriceInfoState] = useState({});
  const [perVehicleOptionsState, setPerVehicleOptionsState] = useState();
  const [requisitionStandardItem, setRequisitionStandardItem] = useState({});
  const [perOrderOptionsState, setPerOrderOptionsState] = useState();
  const [paintOptions, setPaintOptions] = useState([]);
  const [taggedOptionsState, setTaggedOptionsState] = useState([]);
  const [customPaintAttachments, setCustomPaintAttachments] = useState({});
  const [requisitionCartValidations, setRequisitionCartValidations] =
    useState(null);
  const [perVehicleRequiredOptionsState, setPerVehicleRequiredOptionsState] =
    useState();
  const [errorList, setErrorList] = useState([]);
  const { state: errorState } = useContext(ErrorContext);
  const { currentUser } = useCurrentUser();
  const ability = useAppAbility();
  const isEngineer = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.CatalogCodes),
    [ability],
  );

  const toggleViewSelectedOptions = () =>
    setViewSelectedOptions(!viewSelectedOptions);

  const [
    getActiveContractsData,
    { data: activeContractsData, loading: activeContractsDataLoading },
  ] = useLazyQuery(GET_ACTIVE_CONTRACTS, {
    fetchPolicy: 'c',
  });

  const [getStandardItemData, { data: standardItemData }] = useLazyQuery(
    GET_STANDARD_ITEM_BY_ID,
    {
      fetchPolicy: 'c',
    },
  );

  const [
    getRequisitionData,
    { data: requisitionData, loading: requisitionLoading },
  ] = useLazyQuery(GET_REQUISITION_CART, {
    fetchPolicy: 'no-cache',
  });

  const [
    getattachmentTypes,
    { data: attachmentTypes, loading: attachmentLoading },
  ] = useLazyQuery(GET_REQ_ATTACHMENT_TYPES, { fetchPolicy: 'no-cache' });

  const [
    getOptionalReqData,
    { data: optionalReqData, loading: optionalDataLoading },
  ] = useLazyQuery(GET_OPTIONAL_REQ, {
    fetchPolicy: 'c',
  });

  const [
    getCalculatedPriceData,
    { data: calculatedPriceData, loading: priceDataLoading },
  ] = useLazyQuery(GET_CALCULATED_PRICE, {
    fetchPolicy: 'c',
  });

  useEffect(() => {
    if (requisitionId) {
      getRequisitionData({
        variables: { requisitionId },
      });
      getattachmentTypes();
    }
  }, [requisitionId]);

  useEffect(() => {
    if (standardItemData) {
      setRequisitionStandardItem(standardItemData.getStandardItem);
    }
  }, [standardItemData]);

  useEffect(() => {
    if (calculatedPriceData && optionalReqData) {
      const data =
        calculatedPriceData.calculatePurchaseReqPrice.modelCostBreakDown;

      const selectedCalculatedPrice = data.filter((item) => {
        return (
          parseInt(item.contractLineId, 10) ===
          parseInt(currentModLineIdentifier || 0, 10)
        );
      });

      if (
        selectedCalculatedPrice.length &&
        requisitionCartState.requisitionStatus !==
          RequisitionStatus.RECEIVED_BY_GSA
      ) {
        const groupedPerVehicleOptions = groupOptionsPerCatalog(
          optionalReqData,
          selectedCalculatedPrice[0].perVehicleOptions,
        );
        setPerVehicleOptionsState(groupedPerVehicleOptions);
        const groupedPerOrderOptions = groupOptionsPerCatalog(
          optionalReqData,
          selectedCalculatedPrice[0].perOrderOptions,
        );
        setPerOrderOptionsState(groupedPerOrderOptions);
        const gropedPerVehicleRequiredOptions = groupOptionsPerCatalog(
          optionalReqData,
          selectedCalculatedPrice[0].perVehicleRequiredOptions,
        );
        setPerVehicleRequiredOptionsState(gropedPerVehicleRequiredOptions);
      }
      setCalculatedPriceInfoState(selectedCalculatedPrice[0]);

      if (
        requisitionCartState.requisitionStatus ===
        RequisitionStatus.RECEIVED_BY_GSA
      ) {
        const perVehicleOptions =
          requisitionCartState?.vehicles[0]?.vehicleEquipments
            ?.filter((eq) => eq.quantityType === OPTION_QUANTITY_REQUIRED_CODE.NONE || 
              eq.quantityType === OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE)
            .map((equipment) => {
              const {
                quantityType,
                quantity,
                equipmentCode,
                totalOptionPrice,
                unitPriceToCustomer,
              } = equipment;
              return {
                optionCode: equipmentCode,
                quantity,
                optionTotalPrice: totalOptionPrice,
                unitPrice: unitPriceToCustomer,
                optionType: quantityType,
              };
            });
        const groupedPerVehicleOptions = groupOptionsPerCatalog(
          optionalReqData,
          perVehicleOptions,
        );
        setPerVehicleOptionsState(groupedPerVehicleOptions);
        const perOrderOptions =
          requisitionCartState?.vehicles[0]?.vehicleEquipments
            ?.filter((eq) => eq.quantityType === OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER)
            .map((equipment) => {
              const {
                quantityType,
                quantity,
                equipmentCode,
                totalOptionPrice,
                unitPriceToCustomer,
              } = equipment;
              return {
                optionCode: equipmentCode,
                quantity,
                optionTotalPrice: totalOptionPrice,
                unitPrice: unitPriceToCustomer,
                optionType: quantityType,
              };
            });
        const groupedPerOrderOptions = groupOptionsPerCatalog(
          optionalReqData,
          perOrderOptions,
        );
        setPerOrderOptionsState(groupedPerOrderOptions);
        setPerVehicleRequiredOptionsState([]);
      }
    }
  }, [calculatedPriceData, optionalReqData]);

  useEffect(() => {
    if (requisitionData) {
      // testing requisitionCartValidations
      // remove the debug data when AFP13480 and 13481 are accepted
      // console.log(requisitionData)
      const debugMode = query.get('REQdebugMode');
      const requisitionCartValidationResult = debugMode
        ? {
            vehicleAvailability: {
              isContractExpired: debugMode === 'T1',
              makeName: 'Ford',
              modelName: 'F150',
            },
            purchaseFeeChange: {
              oldPurchaseRate: 50,
              newPurchaseRate: 150,
              difference: 100,
            },
            lowPriceChange: {
              oldVendor: 'OV',
              newVendor: 'NV',
              oldLowPrice: 100,
              newLowPrice: 200,
            },
            modelYearChange: {
              oldModelYear: 2020,
              newModelYear: 2021,
            },
            systemAvailability: {
              isSINexpired: debugMode === 'T2',
              makeName: '',
              modelName: '',
            },
          }
        : requisitionData?.getRequisition?.requisitionCartValidations || null;

      setSessionStorageItems(requisitionData?.getRequisition);

      setRequisitionCartValidations(requisitionCartValidationResult);
      setRequisitionCartState(requisitionData.getRequisition);

      const requisition = requisitionData?.getRequisition;
      const standardItem = requisition?.standardItemId;

      setPaintOptions(
        requisition.clientData.selectedOptions?.customerInputs
          ?.paintAndGraphicsOptions,
      );
      setTaggedOptionsState(
        requisition.clientData?.selectedOptions?.customerInputs?.taggedOptions,
      );
      setCustomPaintAttachments(
        requisition.clientData?.selectedOptions?.supportingDocuments,
      );
      setCurrentModLineIdentifier(requisition.contractLineId);
      if (standardItem) {
        getActiveContractsData({
          variables: {
            standardItemId: standardItem,
            transactionType: requisition.transactionType,
          },
        });
        getStandardItemData({
          variables: {
            filter: {
              operator: 'EQ',
              key: 'id',
              value: standardItem,
            },
          },
        });
        getOptionalReqData({
          variables: { standardItemId: standardItem },
        });

        let colorPriceInfo = null;
        if (requisition.contractLineId && requisition.vehicles && requisition.vehicles.length && requisition.vehicles[0].makeColorCode)
          colorPriceInfo = {
            contractLineId: requisition.contractLineId,
            priceToCustomer: requisition.vehicles[0].makeColorPriceToCustomer || 0,
            priceToGsa: requisition.vehicles[0].makeColorPriceToGsa || 0,
          };
          getCalculatedPriceData({
          variables: {
            standardItemId: standardItem,
            quantity: requisition.quantity,
            selectedOptions: withoutAREQOption(
              requisition.clientData.selectedOptions.selectedOptions,
            ),
            ...(colorPriceInfo && { colorPriceInfo }),
            transactionType: requisition.transactionType,
          },
        });
      }
    }
  }, [requisitionData]);

  useEffect(() => {
    if (activeContractsData) {
      const activeContracts =
        activeContractsData.storeGetActiveSinContracts;
      if (
        requisitionData &&
        requisitionData?.getRequisition?.requisitionCartValidations
          ?.vehicleAvailability == null
      ) {
        const selectedActiveContract = activeContracts.filter((item) => {
          return parseInt(item.id, 10) === currentModLineIdentifier;
        });
        setRequisitionContract(selectedActiveContract);
      } else {
        const selectedActiveContract = activeContracts[0];
        if (selectedActiveContract) {
          setRequisitionContract(selectedActiveContract);
        }
      }
    }
  }, [activeContractsData]);

  useEffect(() => {
    if (errorState[STEPS.AGENCY_INFORMATION]?.agencyInformation) {
      const boacErrors = [];
      errorState[STEPS.AGENCY_INFORMATION].agencyInformation.forEach(
        (value) => {
          boacErrors.push(<li>{value}</li>);
        },
      );

      setErrorList(boacErrors);
    }
  }, [errorState]);

  const renderSelectedOptions = () => {
    return (
      <div>
        <SelectedOptions
          activeContracts={
            activeContractsData?.storeGetActiveSinContracts ||
            []
          }
          requisitionCart={requisitionData?.getRequisitionCart}
          standardItemData={standardItemData}
          calculatedPriceData={calculatedPriceData}
          optionalReqData={optionalReqData}
          toggleViewSelectedOptions={toggleViewSelectedOptions}
        />
      </div>
    );
  };

  const renderReviewSummary = () => {
    if (!requisitionCartState) {
      return null;
    }

    const { status } = requisitionCartState;
    const toggleViewSelectedOptionsFunc =
      status === RequisitionStatus.ENGINEERING_REVIEW && isEngineer
        ? toggleViewSelectedOptions
        : null;

    return (
      <>
        {(activeContractsDataLoading ||
          requisitionLoading ||
          priceDataLoading ||
          optionalDataLoading ||
          attachmentLoading) && (
          <div className="afp-modal-overlay draft-requisition-loading">
            <Spinner size="large" className="margin-y-8" />
          </div>
        )}
        {!activeContractsDataLoading &&
          !requisitionLoading &&
          !priceDataLoading &&
          !optionalDataLoading &&
          !attachmentLoading && (
            <ReviewSummaryTab
              setTab={setTab}
              requisitionId={requisitionId}
              requisitionContract={requisitionContract}
              requisitionStandardItem={requisitionStandardItem}
              requisitionCartState={requisitionCartState}
              setRequisitionCartState={setRequisitionCartState}
              perVehicleRequiredOptionsState={perVehicleRequiredOptionsState}
              perOrderOptionsState={perOrderOptionsState}
              standardItemData={standardItemData}
              perVehicleOptionsState={perVehicleOptionsState}
              paintAndGraphicsOptionsState={paintOptions}
              taggedOptionsState={taggedOptionsState}
              totalUploadedFiles={customPaintAttachments}
              requisitionCartValidations={requisitionCartValidations}
              calculatedPriceData={calculatedPriceInfoState}
              toggleViewSelectedOptions={toggleViewSelectedOptionsFunc}
              activeContracts={
                activeContractsData?.storeGetActiveSinContracts ||
                []
              }
              allModelCostBreakDown={
                calculatedPriceData?.calculatePurchaseReqPrice
                  ?.modelCostBreakDown
              }
              attachmentTypes={attachmentTypes?.getRequisitionAttachmentTypes}
              optionalReqData={optionalReqData}
            />
          )}
      </>
    );
  };

  const renderUrgentReqReviewSummary = () => {
    return (
      <UrgentRequirementReviewSummaryTab
        setTab={setTab}
        requisitionId={requisitionId}
        requisitionCartState={requisitionCartState}
        attachmentTypes={attachmentTypes}
      />
    );
  };

  const isAgencyInformationErrorEmpty = () => {
    return !(
      errorState &&
      errorState[STEPS.AGENCY_INFORMATION]?.agencyInformation &&
      errorState[STEPS.AGENCY_INFORMATION]?.agencyInformation?.size > 0
    );
  };

  const renderDetailsMainSection = (reqData) => {
    if (viewSelectedOptions) {
      return renderSelectedOptions();
    }
    const isMas =
      reqData?.requisitionType === REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES;
    const isUrgent =
      reqData?.requisitionType === REQUISITION_TYPE.URGENT_REQUIREMENT;
    // console.log('reqData',reqData)
    // if (
    //   reqData?.requisitionStatus === RequisitionStatus.DRAFT &&
    //   !isUrgent
    // ) {
    //   return renderReviewSummary();
    // }

    const activityTab = () =>
      isUrgent || isMas
        ? renderUrgentReqReviewSummary()
        : renderReviewSummary();

    const isAreq = isAreqType(requisitionData?.getRequisition?.requisitionType);

    return (
      <>
        {isAreq &&
          requisitionData?.getRequisition?.requisitionStatus ===
            RequisitionStatus.ENGINEERING_REVIEW &&
          isEngineer &&
          requisitionData.getRequisition.assignedEngineer?.id !==
            currentUser?.id && (
            <Alert
              type="warning"
              heading="This requisition is unassigned to an engineer"
            >
              In order to take further action on this requisition, it must first
              be assigned to an engineer.
            </Alert>
          )}

        <div className="requisition-details-tabs">
          <TabSet
            tabs={[
              {
                heading: 'Review summary',
                tabSelectedWhenOpen:
                  tab === 'summary' && currentTab !== 'activities',
                content: activityTab(),
              },
              {
                heading: 'Activity',
                tabSelectedWhenOpen:
                  currentTab === 'activities' || tab === 'activities',
                content: (
                  <RequisitionActivitiesTab requisitionId={requisitionId} />
                ),
              },
              {
                heading: 'Attachments',
                tabSelectedWhenOpen: tab === 'attachments',
                content: (
                  <AttachmentTable
                    requisitionId={requisitionId}
                    setTab={setTab}
                    uploadAttachment={isMas || false}
                    status={requisitionData?.getRequisitionCart?.status}
                    reqType={reqData?.requisitionType}
                  />
                ),
              },
            ]}
          />
        </div>
      </>
    );
  };

  const checkUrgentReqErrors = Object.keys(errorState) || [];
  let detailContent = ((!!requisitionContract.length &&
    !_.isEmpty(calculatedPriceInfoState)) ||
    requisitionCartState?.friendlyName) && (
    <>
      {checkUrgentReqErrors.length > 0 &&
        (checkUrgentReqErrors.includes('ContractingFinalReviewInfo') ||
          checkUrgentReqErrors.includes('quoteEvaluation')) && (
          <Alert type="error" heading="This page contains errors">
            Please address all fields outlined in red before proceeding to the
            next step.
          </Alert>
        )}
      <div className="draft-title-section" data-testid="req-detail-title">
        <span className="draft-name">{requisitionCartState.friendlyName}</span>

        <div className="requisition-status">
          <StatusBadge
            variant={
              (
                RequisitionStatusType[
                  requisitionCartState.requisitionStatus
                ] || {
                  color: 'Warning-Gray',
                }
              ).color
            }
          >
            {
              RequisitionStatusType[requisitionCartState.requisitionStatus]
                .label
            }
          </StatusBadge>
        </div>
      </div>

      {(viewSelectedOptions ||
        requisitionCartState?.requisitionStatus === 'FINAL_APPROVAL') && (
        <h2>Customer agency approval</h2>
      )}

      {requisitionCartState?.friendlyName &&
        renderDetailsMainSection(requisitionCartState)}
    </>
  );

  if (
    requisitionCartValidations?.systemAvailability?.isSINexpired ||
    requisitionCartValidations?.vehicleAvailability?.isContractExpired ||
    (requisitionCartValidations?.optionPricesChanges &&
      requisitionCartValidations?.optionPricesChanges.length > 0) ||
    (requisitionCartValidations?.optionsUnavailable &&
      requisitionCartValidations?.optionsUnavailable.length > 0) ||
    (requisitionCartValidations?.optionCollisionChanges &&
      requisitionCartValidations?.optionCollisionChanges.length > 0)
  ) {
    const requisition = requisitionData?.getRequisition;
    const sin = requisition?.standardItemCode || null;
    detailContent = (
      <ReturnOrderModal
        requisitionId={requisitionId}
        requisitionName={requisitionCartState.friendlyName}
        standardItemCode={sin}
        requisitionCartValidations={requisitionCartValidations}
        canSubmitOrder
        visible={false}
      />
    );
  }

  return (
    <>
      <VehicleRequisitionContextProvider>
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <Breadcrumbs />
            <ContactBuyingPopover />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            {!isAgencyInformationErrorEmpty() ? (
              <ErrorMessage
                id="requisition"
                alert={
                  <>
                    {RequisitionBoacErrors.title}
                    {errorList}
                    {RequisitionBoacErrors.footer}
                  </>
                }
                heading={RequisitionBoacErrors.heading}
              />
            ) : null}
          </div>
          <ValidationModal requisitionData={requisitionData} />
          {requisitionCartState?.friendlyName ? (
            detailContent
          ) : (
            <Spinner size="large" className="margin-y-8" />
          )}
        </>
      </VehicleRequisitionContextProvider>
    </>
  );
};

export default RequisitionDetails;
