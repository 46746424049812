import React, {useEffect, useMemo, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from '@gsa/afp-component-library';
import HorizontalPagination from '../../../../components/HorizontalPagination/HorizontalPagination';
import {
  useGetActiveContracts,
  useGetMinOptions,
  useGetCalculatedPrice,
  useGetFeatureOptions,
  useGetAddOptions,
} from '@/requests/leasing';
import ContractImagesSection from '@/components/compare-select/contract-images-section.jsx';
import MinimumRequirementsSection from '../../../../components/MinimumRequirementsSection/MinimumRequirementsSection';
import {
  getDisplayableMinimumRequirements,
  getAvailableMinimumRequirements,
  getDisplayableNetworkCostBreakDownData,
  getDisplayableVehicleInfo,
  getDisplayableVehicleOverview,
  getDisplayableVehicleEngFuel,
  getVehicleOverviewInformation,
  getAvailableVehicleInformation,
  getGroupedOptionalRequirementsData,
  getDisplayableContracts,
  getSimplifiedContractData,
  getSortedActiveContractsDataForLeasing
} from '../../../../utilities/CompareVehicleUtils';
import {
  setActiveEngineInfo,
  setConflictDataOnYes,
  setPagination, setSelectedDeliveryOption,
  setSelectedOptions,
  setSimplifiedContractsData,
} from '../../../../reducers/leasing';
import { getSelectedPayload } from "../../../../utilities/CompareVehicleUtils";
import BasePrice from '../../../../components/BasePrice/BasePrice';
import VehicleInformationAccordion from '../../../../components/VehicleInformationSection/VehicleInformationSection';
import CostBreakdownSection from '../../../../components/CostBreakdownSection/CostBreakdownSection';
import AddOptionsSection from './add-options';
import TotalSellingPriceSection from "./total-selling-price-leasing/total-selling-price";
import "./compare-and-select.scss";
import useLeasingNavigation from '../../../../hooks/useLeasingNavigation';
import { LeasingSteps } from '../../leasing-consts';
import { TRANSACTION_TYPES } from '../../../../utilities/CompareVehicleUtils';
import DeliveryOptionsSection from '@/pages/leasing/steps/compare-and-select/delivery-options.jsx';
import LeasingNavigation from '@/pages/leasing/components/navigation.jsx';
import {useGetCollisionsForSelection} from "../../../../requests/leasing.jsx";
import CloseoutDate from "../../../../components/CloseoutDates/CloseoutDate.jsx";
import VendorClarification from "../../../../components/VendorClarifications/VendorClarification.jsx";

const { appURLs } = window.AFP_CONFIG;

const CompareAndSelect = () => {
  const { 
    selectedStandardItem, 
    compareSelect, 
    destinationType, 
    shipmentState, 
    vehicleQuantity,
    account,
  } = useSelector(
    (state) => state.leasingReducer,
  );
  const {
    activeContracts,
    minOptions,
    calculatedPriceBreakdown,
    featureOptions,
    addOptions,
    pagination,
    selectedOptions,
    simplifiedContractsData,
    activeEngineInfo,
  } = compareSelect;

  const { handleReturnLeasingNavigation } = useLeasingNavigation();
  // if (!selectedStandardItem) {
  //   return handleReturnLeasingNavigation(LeasingSteps.DESTINATION_AND_VEHICLE_TYPE);
  // }


  // const {id: selectedStandardItemId, title, standardItemNumber} = selectedStandardItem;
  const dispatch = useDispatch();
  const [vehicleBodyIcon, setVehicleBodyIcon] = useState(null);
  const [getCollisionsForYes, { loading: isCollisionsLoadingForYes}] = useGetCollisionsForSelection();

  /* compare and select page requests */
  const [getContracts, { loading: isActiveContractsLoading }] =
    useGetActiveContracts(0, 5);
  const [getMinReqs, { loading: isMinOptsLoading }] = useGetMinOptions();
  const [getCalculatedPrice, { loading: isCalculatePriceLoading }] =
    useGetCalculatedPrice();
  const [getFeatureOptions, { loading: isFeatureOptsLoading }] =
    useGetFeatureOptions();
  const [getAddOptions, { loading: isAddOptionsLoading }] = useGetAddOptions();
  const { handleLeasingNavigation } = useLeasingNavigation();
  const shipmentCategory = useMemo(() => {
      console.log('destinationType', destinationType);
      console.log('shipmentState', shipmentState);

      if (destinationType === 'us-territory') {
        return shipmentState;
    } else if (destinationType === 'foreign-county') {
        return 'EXPORT';
    } else {
        return 'ExDel';
    }
  }, [destinationType, shipmentState]);
  /* data massage for sections */
  const sortedActiveContractsData = useMemo(() => {
    return getSortedActiveContractsDataForLeasing(
      activeContracts,
      calculatedPriceBreakdown,
    );
  }, [activeContracts, calculatedPriceBreakdown]);


  const [simplifiedContractsDataNew, activeEngineInfoNew] = useMemo(() => {
    return getSimplifiedContractData(sortedActiveContractsData);
  }, [sortedActiveContractsData]);

  useEffect(() => {
    dispatch(setSimplifiedContractsData(simplifiedContractsDataNew));
  }, [simplifiedContractsDataNew]);

  useEffect(() => {
    dispatch(setActiveEngineInfo(activeEngineInfoNew));
  }, [activeEngineInfoNew]);

  const lowBidContract =
    simplifiedContractsData ? simplifiedContractsData[0] : null;

  const allAvailableVehicleInformation = useMemo(() => {
    return getAvailableVehicleInformation(
      featureOptions,
      simplifiedContractsData,
    );
  }, [featureOptions, simplifiedContractsData]);
  
  const allAvailableVehicleOverview = useMemo(() => {
    return simplifiedContractsData?.length > 0
      ? getVehicleOverviewInformation(simplifiedContractsData)
      : [];
  }, [simplifiedContractsData]);
  
  const minimumRequirementData = useMemo(() => {
    return getAvailableMinimumRequirements(
      minOptions,
      simplifiedContractsData,
    );
  }, [simplifiedContractsData, minOptions]);
  
  const { allAvailableOptionalReqData, deliveryOptions } = 
  useMemo(() => {
    return getGroupedOptionalRequirementsData(
      addOptions,
      simplifiedContractsData,
      shipmentCategory,
    );
  }
    , [JSON.stringify(addOptions), JSON.stringify(simplifiedContractsData), shipmentCategory]);

  /* All sections pagionation handles here */
  const paginatedContracts = useMemo(() => {
    return simplifiedContractsData?.length > 0 ? getDisplayableContracts(
      pagination.page,
      pagination.offset,
      simplifiedContractsData,
    ) : [];
  }, [simplifiedContractsData, pagination]);
  
  const allAvailableMinimumRequirements = useMemo(() => {
    return getDisplayableMinimumRequirements(
      pagination.page,
      pagination.offset,
      minimumRequirementData,
    );
  }, [minimumRequirementData, pagination]);
  
  const paginatedCost = useMemo(() => {
    return calculatedPriceBreakdown?.length > 0
    ? getDisplayableNetworkCostBreakDownData(
        pagination.page,
        pagination.offset,
        calculatedPriceBreakdown,
      )
    : [];
  }, [calculatedPriceBreakdown, pagination]);
  
  const displayableOtherFeatures = useMemo(() => {
    return allAvailableVehicleInformation?.length > 0
    ? getDisplayableVehicleInfo(
        pagination.page,
        pagination.offset,
        allAvailableVehicleInformation,
      )
    : [];
  }, [allAvailableVehicleInformation, pagination]);
  
  const displayableOverview = useMemo(() => {
    return allAvailableVehicleOverview?.length > 0
    ? getDisplayableVehicleOverview(
        pagination.page,
        pagination.offset,
        allAvailableVehicleOverview,
      )
    : [];
  }, [allAvailableVehicleOverview, pagination]);
  
  const displayableVehicleEngFuel = useMemo(() => {
    return simplifiedContractsData?.length > 0
    ? getDisplayableVehicleEngFuel(
        pagination.page,
        pagination.offset,
        activeEngineInfo,
      )
    : [];
  }, [activeEngineInfo, pagination]);
  
  const resetPriceCalculation = (id) => {
    getCalculatedPrice({
      variables: {
        standardItemId: id,
        quantity: vehicleQuantity,
        selectedOptions: [],
        transactionType: TRANSACTION_TYPES.LEASING,
        fmcId: parseInt(account?.fmcId),
        standardItemNumber: selectedStandardItem?.standardItemNumber
      },
    });

    getCollisionsForYes({
      variables: {
        standardItemId: parseFloat(id),
        affectedOption: null,
        selectedOptions: [],
        transactionType: TRANSACTION_TYPES.LEASING
      }
    }).then(collisionResult => {
      dispatch(setConflictDataOnYes(collisionResult.data));
    });
  };

  useMemo(() => {
    if (selectedStandardItem?.id) {
      getContracts({
        variables: {
          standardItemId: +selectedStandardItem?.id,
          transactionType: TRANSACTION_TYPES.LEASING,
        },
      });
      getMinReqs({
        variables: { standardItemId: parseFloat(selectedStandardItem?.id) },
      });
      getCalculatedPrice({
        variables: {
          standardItemId: parseFloat(selectedStandardItem?.id),
          quantity: vehicleQuantity,
          selectedOptions: selectedOptions?.length > 0 ? getSelectedPayload(selectedOptions) : [],
          transactionType: TRANSACTION_TYPES.LEASING,
          fmcId: parseInt(account?.fmcId),
          standardItemNumber: selectedStandardItem?.standardItemNumber
        },
      });
      getAddOptions({
        variables: { standardItemId: parseFloat(selectedStandardItem?.id) },
      });
      getFeatureOptions({
        variables: { standardItemId: parseFloat(selectedStandardItem?.id) },
      });
      const standardItemSubCategoryCode = selectedStandardItem?.vehicleType;
      const svgCode = standardItemSubCategoryCode?.concat('_black');
      const iconURL = `${appURLs.cdnUrl}/icons/sin-sub-category/${svgCode}.svg`;
      setVehicleBodyIcon(iconURL);
    }
  }, [selectedStandardItem]);

  const handlePreviousNavigation = () => {
    handleLeasingNavigation(LeasingSteps.DESTINATION_AND_VEHICLE_TYPE);
  };

  const handleCompareVehiclesPagination = (value) => {
    dispatch(setPagination({ page: value, offset: pagination.offset }));
  };

  const handleImageError = () => {
    const iconURL = `${appURLs.cdnUrl}/icons/sin-sub-category/02.svg`;
    setVehicleBodyIcon(iconURL);
  };

  const loading =
    isActiveContractsLoading ||
    isMinOptsLoading ||
    isCalculatePriceLoading ||
    isFeatureOptsLoading ||
    isAddOptionsLoading ||
    isCollisionsLoadingForYes;

  return (
    <div className="compare-vehicles">
      {loading ? (
        <div className="afp-modal-overlay modalContainer conflict-modal">
          <Spinner size="large" className="margin-y-8" />
        </div>
      ) : (
        <>
          <div className="leasing-compare-select-header-section">
            <div className="store-compare-vehicles-sub-header">
              <img
                data-testid="store-compare-sub-header-icon"
                className="store-compare-vehicles-sub-header-image"
                src={vehicleBodyIcon}
                alt="ambulance icon"
                onError={() => {
                  handleImageError();
                }}
              />
              <h3 data-testid="compare-vehicles-sub-header-heading">
                {selectedStandardItem?.standardItemNumber} -{' '}
                {selectedStandardItem?.title}
              </h3>
            </div>
          </div>
          <div className="page-navigation-section">
            <div>
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  resetPriceCalculation(parseFloat(selectedStandardItem?.id));
                  dispatch(setSelectedOptions([]));
                  dispatch(setSelectedDeliveryOption([]))
                }}
                data-testid="store-compare-vehicles-clear-all-selections-button"
                aria-label="Clear all selections"
                leftIcon={{ name: 'custom_undo', type: 'custom' }}
                label="Clear all selections"
              />
            </div>

            <div>
              <HorizontalPagination
                offset={pagination.offset}
                id="store-compare-vehicles"
                currentPage={pagination.page}
                handlePageChange={handleCompareVehiclesPagination}
                totalNumberOfResults={simplifiedContractsData?.length}
              />
            </div>
          </div>
          {!!paginatedContracts?.length && (
            <>
              <ContractImagesSection
                contracts={paginatedContracts}
                lowBidContract={lowBidContract}
                quantity={vehicleQuantity}
              />
              <CloseoutDate
                  contracts={paginatedContracts}
                  lowestBidData={lowBidContract}
              />
              <VendorClarification
                  contracts={paginatedContracts}
                  lowestBidData={lowBidContract}
              />
              <MinimumRequirementsSection
                minimumRequirementsData={allAvailableMinimumRequirements}
                lowestBidData={lowBidContract}
              />
              <VehicleInformationAccordion
                vehicleInformationData={{
                  vehicleOtherFeaturesData: displayableOtherFeatures,
                  vehicleOverviewData: displayableOverview,
                  vehicleEngFuelData: displayableVehicleEngFuel,
                }}
                lowestBidData={lowBidContract}
              />
              <BasePrice
                contracts={paginatedContracts}
                lowestBidData={lowBidContract}
                networkCostBreakDownData={paginatedCost}
              />
              {deliveryOptions && deliveryOptions.length > 0 && (
                <DeliveryOptionsSection
                  deliveryOptions={deliveryOptions}
                  pagination={pagination}
                  handleRowCheckBoxClick={() => {}}
                />
              )}
              <AddOptionsSection
                allAvailableOptionalReqData1={allAvailableOptionalReqData}
                lowestBidData={lowBidContract}
              />
              <CostBreakdownSection
                lowestBidData={lowBidContract}
                networkCostBreakDownData={paginatedCost}
              />
              <TotalSellingPriceSection
                contracts={paginatedContracts}
                lowestBidData={lowBidContract}
                networkCostBreakDownData={paginatedCost}
              />
            </>
          )}

          <LeasingNavigation
            onPrevious={handlePreviousNavigation}
            hide={['next','cancel', 'submit', 'reject']}
          />
        </>
      )}
    </div>
  );
};

export default CompareAndSelect;
