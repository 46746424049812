export const buildSelectedEngine = (requisition) => {
  if (!requisition?.vehicles?.length) {
    return null;
  }
  const vehicle = requisition.vehicles[0];
  return {
    fuelType: vehicle.fuelCode,
    convMpgCity: vehicle.convMpgCity,
    convMpgHighway: vehicle.convMpgHighway,
    convMpgCombined: vehicle.convMpgCombined,
    convGpm: vehicle.convGpm,
    altFuelType: vehicle.altFuelCode,
    altMpgCity: vehicle.altMpgCity,
    altMpgHighway: vehicle.altMpgHighway,
    altMpgCombined: vehicle.altMpgCombined,
    altGpm: vehicle.altGpm,
  }
}