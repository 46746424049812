import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect, useContext } from 'react';
import './TotalSellingPrice.scss';
import cn from 'classnames';
import { Modal, Button } from '@gsa/afp-component-library';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { GiCheckMark } from 'react-icons/gi';
import {
  getResolvedOptionsUpdated,
  getUpdatedResolvedOptionsOnCancel,
} from '../../utilities/TotalSellingPriceUtils';
import ArrowRightSvg from '../../assets/images/arrow-right.svg';
import { formatCurrency, OPTION_QUANTITY_REQUIRED_CODE } from '../../utilities/CompareVehicleUtils';
import RenderUnBuildableOptions from './NonBuildConflictModal';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import WarningSvg from '../../assets/images/warning-icon.svg';
import { useQueryParam } from '../../hooks/useQueryParam';
import { STEPS } from '../../pages/VehicleRequisition/constants/VehicleRequisitionConstants';
import EngineeringReviewModalComponent from '../EngineeringReviewModal';
import InputRequiredOptionsModal from '../InputRequiredOptionsModal/InputRequiredOptionsModal';
import { IN_REQ_TAG, TagsToWatch } from '../../constants/constants';
import EngineeringReviewWithInputRequiredModal from '../EngineeringReviewModal/EngineeringReviewWithInputRequiredModal';

const TotalSellingPrice = ({
  contracts,
  lowestBidData,
  networkCostBreakDownData,
  selectedContract,
  updateDraftRequisition,
  dispatch,
  isCalculatePriceDisabled,
  handleCalculatePrice,
  isCalculatePriceError,
  getPurchaseCollisionDataOnYes,
  getPurchaseCollisionInfo,
  activeEngineInfo,
}) => {
  const standardItem = JSON.parse(sessionStorage.getItem('standardItem'));
  const unResolvedData =
    getPurchaseCollisionInfo?.getPurchaseCollisionForAllVendor
      ?.vendorCollisionsPerOption;

  const contextState = useContext(VehicleRequisitionContext);

  const query = useQueryParam();
  const draftId = query.get('requisitionId');
  const mode = query.get('mode');

  const previouslySelectedContract = useRef(selectedContract);

  const [nonLowPriceSelect, setNonLowPriceSelect] = useState(false);

  // rengineering modal confirmation state
  const [
    reengineeringConfirmationContract,
    setReengineeringConfirmationContract,
  ] = useState(null);

  const [selectedContractToBuild, setSelectedContractToBuild] = useState(null);

  const [showCalculatePriceModal, setShowCalculatePriceModal] = useState(false);

  const [modalIsUnbuildable, setModalIsUnBuildable] = useState(false);
  const [showEngineeringReviewModal, setShowEngineeringReviewModal] =
    useState(false);

  // flag for showing modal when Non direct contract is selected
  const [showCoNonDirectReviewModal, setShowCoNonDirectReviewModal] =
    useState(false);

  const [showInputRequiredModal, setShowInputRequiredModal] = useState(false);

  const [conflictTooltip, setConflictTooltip] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [purchaseCollisionSelected, setPurchaseCollisionSelected] = useState(
    [],
  );
  const [isCanceled, setIsCanceled] = useState(false);
  const [showDeliveryOptionsModal, setShowDeliveryOptionsModal] =
    useState(false);
  const [
    showEngineeringReviewModalWithInputRequired,
    setShowEngineeringReviewModalWithInputRequired,
  ] = useState(false);

  useEffect(() => {
    if (modalIsUnbuildable) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalIsUnbuildable]);

  const activateLowestBid = (contract) => {
    return contract.contractLineId === lowestBidData.contractLineId
      ? 'lowest-bid'
      : '';
  };
  const GSA_PURCHASE_FEE =
    networkCostBreakDownData[0].purchaseRate &&
    networkCostBreakDownData[0].purchaseRate > 1.0
      ? `${(
          networkCostBreakDownData[0].purchaseRate * 100 -
          100
        ).toPrecision()}%`
      : '2%';

  const selectModelToBuild = (contract, isContinued) => {
    if (!isCalculatePriceDisabled) {
      setShowCalculatePriceModal(true);
      return;
    }
    const verifyNonLowPrice =
      contract.contractLineId === lowestBidData.contractLineId;
    setSelectedContractToBuild(contract);

    const selectedEngine = activeEngineInfo.find(x => x.contractLineId === contract.contractLineId);

    if (verifyNonLowPrice || isContinued) {
      const foundCostBreakDown = networkCostBreakDownData.find(
        (cb) => cb.contractLineId === contract.contractLineId,
      );

      if (
        previouslySelectedContract.current &&
        previouslySelectedContract.current.contractLineId !==
          contract.contractLineId
      ) {
        dispatch({
          type: VehicleRequisitionContextActions.RESET_OLD_SELECTED_CONTRACT_INFO,
          selectedContract: contract,
          selectedEngine,
          selectedContractCostBreakdown: foundCostBreakDown,
          currentStep: {
            key: STEPS.PAINT_GRAPHICS,
            current: 2,
            text: 'Paint and graphics',
          },
        });
      } else {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_SELECTED_CONTRACT_INFO,
          selectedContract: contract,
          selectedEngine,
          selectedContractCostBreakdown: foundCostBreakDown,
          currentStep: {
            key: STEPS.PAINT_GRAPHICS,
            current: 2,
            text: 'Paint and graphics',
          },
        });
      }

      if (draftId) {
        if (query.has('requisitionType')) {
          query.delete('requisitionType');
          const updatedUrl = `${window.location.pathname}?${query.toString()}`;
          window.history.replaceState(null, '', updatedUrl);
        }
        updateDraftRequisition(draftId, {
          selectedContract: contract,
        });
      }
    } else {
      setNonLowPriceSelect(true);
    }
  };

  const handleUnBuildableBtnClick = () => {
    setModalIsUnBuildable(true);
  };

  const unResolvedOptionsSelectionHandler = (
    selectedOption,
    selectedVendorIdentifier,
    optionCodeData,
  ) => {
    const conflictResolvedOptionsData =
      contextState.state.requiredOptionSessionState;
    const updatedPayload = getResolvedOptionsUpdated(
      selectedOption,
      selectedVendorIdentifier,
      optionCodeData,
      conflictResolvedOptionsData,
    );
    setPurchaseCollisionSelected(updatedPayload);
    setIsCanceled(false);
  };

  const modalCloseHandler = () => {
    setIsCanceled(true);
    setModalIsUnBuildable(false);
    const prevPurchaseCollisionSelected = purchaseCollisionSelected;
    const data =
      unResolvedData &&
      unResolvedData.filter((item) => item.contractLineId === selectedVendor);

    const updatedPayload = getUpdatedResolvedOptionsOnCancel(
      data,
      selectedVendor,
      prevPurchaseCollisionSelected,
    );

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION,
      payload: updatedPayload,
    });
  };

  const handleAfterResolving = () => {
    setModalIsUnBuildable(false);

    if (purchaseCollisionSelected.length && !isCanceled) {
      handleCalculatePrice(true, purchaseCollisionSelected);
      if (typeof getPurchaseCollisionDataOnYes === 'function') {
        getPurchaseCollisionDataOnYes({
          variables: {
            standardItemId: standardItem.standardItemId,
            selectedOptions: purchaseCollisionSelected,
          },
        });
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION,
          payload: purchaseCollisionSelected,
        });
      }
    }
  };

  const getSelectedTaggedOptions = (requiredTag) => {
    const { state } = contextState;
    const { addOptionsState } = state;
    const results = [];
    addOptionsState?.forEach((opts) => {
      if (opts && opts.options) {
        opts.options.forEach((opt) => {
          if (
            opt.isChecked &&
            opt.tags?.value &&
            opt.tags.value.length > 0 &&
            opt.tags.value.includes(requiredTag)
          ) {
            results.push({
              optionCategoryCode: opts.optionCategoryCode,
              optionCategoryName: opts.optionCategoryName,
              optionDescription: opt.optionDescription,
              tags: opt.tags,
            });
          }
        });
      }
    });
    return results;
  };

  const reengineeringConfirmAction = (_contract = undefined) => {
    const contract = _contract || reengineeringConfirmationContract.contract;

    if (contract) {
      setShowEngineeringReviewModal(false);
      setShowInputRequiredModal(false);
      setShowEngineeringReviewModalWithInputRequired(false);
      if (contract?.isVendorDirect === 0) {
        setSelectedContractToBuild(contract);
        setShowCoNonDirectReviewModal(true);
      } else {
        selectModelToBuild(contract, false);
        if (conflictTooltip) {
          setConflictTooltip(false);
        }
      }
    }
  };

  const selectedDeliveryOption = (deliveryOptions) => {
    const option = deliveryOptions
      .flatMap((deliveryOption) => deliveryOption.options)
      .find((op) => op.isChecked);
    return option || null;
  };

  const isSelectedDeliveryOptionAvailable = (
    selectedOption,
    contractLineId,
  ) => {
    if (selectedOption !== null && selectedOption.vendorValues) {
      return selectedOption.vendorValues.some((value) => {
        return (
          value.equipmentCode === selectedOption.optionCode &&
          value.contractLineId === contractLineId
        );
      });
    }
    return false;
  };

  const handleSelectButton = (unBuildableOption, contract) => {
    const { state } = contextState;
    const { quantityHasError, formErrorFocus, addOptionsState } = state;

    if (quantityHasError) {
      const { vehicleQuantity } = formErrorFocus;
      if (vehicleQuantity) {
        vehicleQuantity.current?.focus();
      }
      return;
    }

    const selectedOption = selectedDeliveryOption(state.deliveryOptions);
    if (
      selectedOption &&
      !isSelectedDeliveryOptionAvailable(
        selectedOption,
        contract.contractLineId,
      )
    ) {
      setShowDeliveryOptionsModal(true);
      return;
    }

    // verify if there is any checkbox checked, but no quantity
    let hasErrors = false;
    const optionsErrors = {};
    addOptionsState.every((optionCategory) => {
      const { options } = optionCategory;

      options.every((option) => {
        const hasNumInput =
          option.quantityRequiredCode === OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE ||
          option.quantityRequiredCode === OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER;
        optionsErrors[option.optionCode] = hasNumInput
          ? option.isChecked && !option.optionValue
          : false;

        if (optionsErrors[option.optionCode]) {
          hasErrors = true;
        }

        return true;
      });
      return true;
    });
    if (hasErrors) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_ERRORS,
        payload: optionsErrors,
      });
      return;
    }

    // set the needed variables to go to next step after reengineering modal
    setReengineeringConfirmationContract({
      contract,
    });
    if (unBuildableOption && !unBuildableOption.isUnBuildable) {
      if (isCalculatePriceDisabled) {
        if (
          getSelectedTaggedOptions(TagsToWatch[0]).length > 0 &&
          getSelectedTaggedOptions(IN_REQ_TAG).length > 0
        ) {
          setShowEngineeringReviewModalWithInputRequired(true);
        } else if (getSelectedTaggedOptions(IN_REQ_TAG).length > 0) {
          setShowInputRequiredModal(true);
        } else if (getSelectedTaggedOptions(TagsToWatch[0]).length > 0) {
          setShowEngineeringReviewModal(true);
        } else if (
          contract?.isVendorDirect === 0
        ) {
          setSelectedContractToBuild(contract);
          setShowCoNonDirectReviewModal(true);
        } else {
          reengineeringConfirmAction(contract);
        }
      } else {
        selectModelToBuild(contract, false);
        if (conflictTooltip) {
          setConflictTooltip(false);
        }
      }
    } else {
      setSelectedVendor(contract.contractLineId);
      if (conflictTooltip && selectedVendor !== contract.contractLineId) {
        setConflictTooltip(true);
      } else {
        setConflictTooltip(!conflictTooltip);
      }
    }
  };

  return (
    <>
      <div className="afp-store-total-selling-price">
        <table className="usa-table full-width-table" role="presentation">
          <tbody>
            <tr>
              <td
                data-testid="total-sale-price-cell"
                className="total-sale-price-title-cell"
              >
                Total selling price:
                <span className="total-sp-sub-desc">
                  (Includes {GSA_PURCHASE_FEE} GSA purchasing fee)
                </span>
              </td>
              {networkCostBreakDownData.map((contract) => {
                const contractFragmentKey = `${contract.contractLineId}`;
                const unBuildableInfo = contract.isUnBuildable;
                return (
                  <React.Fragment key={contractFragmentKey}>
                    <td
                      data-testid={`${contract.contractLineId}-total-sale-price`}
                      key={`${contract.contractLineId}-total-sale-price`}
                      className={cn(
                        'contract-cell',
                        activateLowestBid(contract),
                      )}
                    >
                      {formatCurrency(contract.totalSellingPrice)}{' '}
                      {unBuildableInfo ? '*' : ''}
                    </td>
                  </React.Fragment>
                );
              })}
            </tr>
            {(mode === 'requisition' || !!draftId) && (
              <>
                <tr>
                  <td
                    data-testid="start-requisition"
                    className="total-sale-price-title-cell requisition"
                  >
                    Start your Requisition
                    <img
                      className="requisition-arrow"
                      src={ArrowRightSvg}
                      alt="requisition direction icon"
                    />
                  </td>
                  {contracts.map((contract) => {
                    const unBuildableOption =
                      networkCostBreakDownData &&
                      networkCostBreakDownData.find((item) => {
                        return item.contractLineId === contract.contractLineId;
                      });

                    return (
                      <td
                        data-testid={`${contract.contractLineId}-select-vehicle`}
                        key={`select-vehicle-${contract.contractLineId}`}
                        className={cn(
                          'contract-cell',
                          activateLowestBid(contract),
                          'requisition-cell',
                        )}
                      >
                        <Button
                          data-testid={`${contract.contractLineId}-select-model`}
                          className="req-select-button tooltip margin-0"
                          type="button"
                          variant="outline"
                          onClick={() => {
                            if (!isCalculatePriceError) {
                              handleSelectButton(unBuildableOption, contract);
                            }
                          }}
                          label={
                            unBuildableOption &&
                            !unBuildableOption.isUnBuildable
                              ? 'Select This Model'
                              : 'Non Buildable Model'
                          }
                        />
                        <span className="non-buildable-tooltip">
                          <div
                            className={`non-buildable-tooltip-info ${
                              conflictTooltip &&
                              selectedVendor === contract.contractLineId
                                ? 'non-buildable-tooltip-block'
                                : ''
                            }`}
                          >
                            <img src={WarningSvg} alt="warning icon" />
                            This model is non-buildable due to unresolved
                            conflicts.
                            <div>
                              <button
                                type="button"
                                className="conflict-link"
                                onClick={() => {
                                  handleUnBuildableBtnClick();
                                  setConflictTooltip(false);
                                }}
                              >
                                View Conflict
                              </button>
                            </div>
                            <i />
                          </div>
                        </span>
                      </td>
                    );
                  })}
                </tr>
                {selectedContract && Object.keys(selectedContract).length ? (
                  <tr>
                    <td
                      aria-label="td"
                      data-testid="start-requisition"
                      className="total-sale-price-title-cell requisition"
                    />
                    {contracts.map((contract) => {
                      return (
                        <td
                          data-testid={`${contract.contractLineId}-pre-selected-vendors`}
                          className="pre-selected-vendors"
                        >
                          {selectedContract?.contractLineId ===
                            contract.contractLineId && (
                            <>
                              {contract.contractLineId ===
                                lowestBidData.contractLineId && (
                                <span className="selected-vendor-container lowest-bid-container">
                                  <GiCheckMark />
                                  <span className="selected-vendor-title">
                                    LOWEST BID
                                  </span>
                                </span>
                              )}
                              <span className="selected-vendor-container">
                                <AiOutlineArrowUp />
                                <span className="selected-vendor-title">
                                  SELECTED VENDOR
                                </span>
                              </span>
                            </>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ) : null}
              </>
            )}
          </tbody>
        </table>
        <div className="conflict-info">{selectedContract?.contractLineId}</div>
      </div>
      {showDeliveryOptionsModal && (
        <div className="afp-modal-overlay modalContainer low-bid-justification">
          <Modal
            title={
              <span className="justification-title">
                Delivery option not available
              </span>
            }
            onClose={() => {
              setShowDeliveryOptionsModal(false);
            }}
            className="justification-modal"
            actions={
              <Button
                type="button"
                className="modal-close-button"
                onClick={() => {
                  setShowDeliveryOptionsModal(false);
                }}
                label="Close"
              />
            }
          >
            <p>
              Delivery option not available for selected vehicle. Please revise
              your delivery option or select a different vehicle model.
            </p>
          </Modal>
        </div>
      )}
      {nonLowPriceSelect && (
        <div className="afp-modal-overlay modalContainer low-bid-justification">
          <Modal
            title={
              <span className="justification-title">
                Non-low price vehicle selected
              </span>
            }
            onClose={() => {
              setNonLowPriceSelect(false);
            }}
            className="justification-modal"
            actions={
              <>
                <Button
                  type="button"
                  className="modal-cancel-button"
                  onClick={() => {
                    setNonLowPriceSelect(false);
                  }}
                  label="No, stay on compare and select"
                />
                <Button
                  type="button"
                  className="modal-close-button"
                  onClick={() => {
                    selectModelToBuild(selectedContractToBuild, true);
                  }}
                  label="Yes, proceed with requisition"
                />
              </>
            }
          >
            <p>
              In accordance with the Federal Acquisition Regulation Part 16.505,
              you must provide a written justification when the lowest priced
              vehicle as equipped is not selected. Your best value justification
              will be included in the order file and is subject to Inspector
              General review.
            </p>
            <p>
              You will be required to provide a justification. Are you sure you
              want to continue?
            </p>
          </Modal>
        </div>
      )}

      {showCalculatePriceModal && (
        <div className="afp-modal-overlay modalContainer low-bid-justification">
          <Modal
            title={<h1>Updated Prices Available</h1>}
            onClose={() => {
              setShowCalculatePriceModal(false);
            }}
            className="justification-modal"
            actions={
              <>
                <Button
                  type="button"
                  className="modal-cancel-button"
                  onClick={() => {
                    setShowCalculatePriceModal(false);
                  }}
                  label="Cancel"
                />
                <Button
                  type="button"
                  className="modal-close-button"
                  onClick={() => {
                    handleCalculatePrice();
                    setShowCalculatePriceModal(false);
                  }}
                  label="Calculate Price"
                />
              </>
            }
          >
            <p>
              Please select <strong>Calculate Price</strong> below to see
              updated prices prior to selecting a model
            </p>
          </Modal>
        </div>
      )}

      {modalIsUnbuildable && (
        <div className="afp-modal-overlay modalContainer conflict-modal">
          <Modal
            title="This model is currently non-buildable due to the following conflicts"
            className="collision-found-modal"
            onClose={() => {
              modalCloseHandler();
            }}
            actions={
              <>
                <Button
                  type="button"
                  className="cancel-button"
                  onClick={() => {
                    modalCloseHandler();
                  }}
                  label="No, cancel my selection"
                />

                <Button
                  type="button"
                  data-testid="yes-apply-changes-btn"
                  onClick={() => handleAfterResolving()}
                  label="Yes, apply changes"
                />
              </>
            }
          >
            <RenderUnBuildableOptions
              unResolvedData={unResolvedData}
              selectedVendor={selectedVendor}
              getPurchaseCollisionDataOnYes={getPurchaseCollisionDataOnYes}
              unResolvedOptionsSelectionHandler={
                unResolvedOptionsSelectionHandler
              }
            />
          </Modal>
        </div>
      )}

      {showEngineeringReviewModal && (
        <>
          <EngineeringReviewModalComponent
            selectedOptionsFn={getSelectedTaggedOptions}
            selectedVendor={selectedVendor}
            confirmContinueAction={reengineeringConfirmAction}
            closeModalFn={() => {
              setShowEngineeringReviewModal(false);
            }}
          />
        </>
      )}

      {showCoNonDirectReviewModal && (
        <div className="afp-modal-overlay modalContainer low-bid-justification">
          <Modal
            title={
              <span className="justification-title">
                Engineering and/or CO Review Required
              </span>
            }
            onClose={() => {
              setShowCoNonDirectReviewModal(false);
            }}
            className="justification-modal"
            actions={
              <>
                <Button
                  type="button"
                  className="modal-cancel-button"
                  onClick={() => {
                    setShowCoNonDirectReviewModal(false);
                  }}
                  label="No, stay on compare and select"
                />
                <Button
                  type="button"
                  className="modal-close-button"
                  onClick={() => {
                    selectModelToBuild(selectedContractToBuild, true);
                  }}
                  label="Yes, proceed with requisition"
                />
              </>
            }
          >
            <p>
              You have selected a vehicle that will require technical approval
              from GSA Engineering and funding approval by GSA Contracting. Once
              this requisition is submitted to GSA it will incur additional time
              for processing prior to order placement.
            </p>
          </Modal>
        </div>
      )}

      {showInputRequiredModal && (
        <InputRequiredOptionsModal
          selectedOptionsFn={getSelectedTaggedOptions}
          selectedVendor={selectedVendor}
          confirmContinueAction={reengineeringConfirmAction}
          closeModalFn={() => {
            setShowInputRequiredModal(false);
          }}
        />
      )}

      {showEngineeringReviewModalWithInputRequired && (
        <EngineeringReviewWithInputRequiredModal
          confirmContinueAction={reengineeringConfirmAction}
          closeModalFn={() => {
            setShowEngineeringReviewModalWithInputRequired(false);
          }}
        />
      )}
    </>
  );
};

TotalSellingPrice.propTypes = {
  contracts: PropTypes.instanceOf(Array),
  lowestBidData: PropTypes.instanceOf(Object),
  networkCostBreakDownData: PropTypes.instanceOf(Array),
  selectedContract: PropTypes.instanceOf(Object),
  dispatch: PropTypes.func.isRequired,
  updateDraftRequisition: PropTypes.func.isRequired,
  isCalculatePriceDisabled: PropTypes.bool.isRequired,
  handleCalculatePrice: PropTypes.func.isRequired,
  isCalculatePriceError: PropTypes.bool.isRequired,
  getPurchaseCollisionDataOnYes: PropTypes.func,
  getPurchaseCollisionInfo: PropTypes.func,
};

TotalSellingPrice.defaultProps = {
  contracts: [],
  lowestBidData: {},
  networkCostBreakDownData: [],
  selectedContract: {},
  getPurchaseCollisionDataOnYes: () => {},
  getPurchaseCollisionInfo: () => {},
};

export default TotalSellingPrice;
