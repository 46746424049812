import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Alert,
  Button,
  Modal,
  Textbox,
  SelectDropdown,
  DatePicker,
} from '@gsa/afp-component-library';
import { validateVINFormat } from '../../utilities/commonUtils';
import { formatDate } from '../../pages/order-detail/constants/order-vehicle-constants';
import FieldFeedback from '../FieldFeedback/FieldFeedback';

const feedback = {
  serialNumberVin: ['This is required field', 'VIN is invalid'],
  shipmentDate: ['This is required field'],
  deliveryDate: ['This is required field'],
};

const isValidDate = (value) => {
  const date = moment(value);

  if (!date.isValid()) {
    return false;
  }
  const dateFormat = 'MM/DD/YYYY';

  const sanitizeInput = moment(date).format(dateFormat);

  if (Number(sanitizeInput.split('/')[2]) < 1900) {
    return false;
  }
  return true;
};

const UpdateVehicleModal = ({
  isOpen,
  orderId,
  onSubmit,
  onCancel,
  defaultValue,
  selectedVehicle,
  title,
  body,
  maxLength,
  inputProps,
  ...props
}) => {

  const vehicleType =
    selectedVehicle?.original?.order?.standardItem?.vehicleType;

  const [showModal, setShowModal] = useState(false);
  const [errorShipmentDate, setErrorShipmentDate] = useState(null);
  const [errorDeliveryDate, setErrorDeliveryDate] = useState(null);
  const testId = props['data-testid'] || 'updated-vehicle';
  const [errorUpdating, errorUpdatingTo] = useState('');
  const [values, setValues] = useState({});
  const [visibility, setVisibility] = useState(
    Object.keys(feedback).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );
  const [feedbackText, setFeedbackText] = useState(
    Object.keys(feedback).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );

  function setErrors(errors) {
    const visible = { ...visibility };
    const feed = { ...feedbackText };
    errors.forEach((err) => {
      visible[err.key] = err.visibility;
      feed[err.key] = err.text;
    });
    setVisibility(visible);
    setFeedbackText(feed);
  }

  const [focus, setFocus] = useState(
    Object.keys(feedback).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );
  const formattedDate = (value) => {
    if (!value) {
      return '';
    }
    const momentDate = formatDate(value);
    return momentDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, '$3-$1-$2');
  };

  const validateValue = (key, rawValue, focused) => {
    const errors = [];
    const value = rawValue?.toString()?.trim();
    switch (key) {
      case 'serialNumberVin':
        if (!value && !focused) {
          errors.push({
            key,
            visibility: 'error',
            text: feedback[key][0],
          });
        } else if (value === '' && focused) {
          errors.push({
            key,
            visibility: false,
            text: feedback[key][0],
          });
        } else if (value && !validateVINFormat(value, vehicleType)) {
          errors.push({
            key,
            visibility: 'error',
            text: feedback[key][1],
          });
        } else {
          errors.push({ key, visibility: false, text: false });
        }
        break;
      case 'shipmentDate':
        if (!value && !focused) {
          errors.push({
            key,
            visibility: 'error',
          });
        } else if (!value && focused) {
          errors.push({
            key,
            visibility: false,
            text: false,
          });
        } else if (value) {
          if (!isValidDate(value)) {
            errors.push({
              key,
              visibility: 'error',
              text: false,
            });
            // Validating here as date-picker allowing manual entry of past dates than min date
          }
        } else {
          errors.push({ key, visibility: false, text: false });
        }
        break;
      case 'deliveryDate':
        if (!value && !focused) {
          errors.push({
            key,
            visibility: 'error',
          });
        } else if (!value && focused) {
          errors.push({
            key,
            visibility: false,
            text: false,
          });
        } else if (value) {
          if (!isValidDate(value)) {
            errors.push({
              key,
              visibility: 'error',
              text: false,
            });
            // Validating here as date-picker allowing manual entry of past dates than min date
          }
        } else {
          errors.push({ key, visibility: false, text: false });
        }
        break;

      default:
        break;
    }

    return { errors };
  };

  function clearErrors(keys) {
    const newVisibility = Object.keys(visibility).map((key) => {
      if (keys.indexOf(key) > -1) visibility[key] = false;
      return key;
    });
    setVisibility(newVisibility);

    const newFeedbackText = Object.keys(feedbackText).map((key) => {
      if (keys.indexOf(key) > -1) feedbackText[key] = false;
      return key;
    });
    setFeedbackText(newFeedbackText);
  }

  useEffect(() => {
    errorUpdatingTo(props.errorUpdating);
  }, [props.errorUpdating]);

  useEffect(() => {
    clearErrors(['serialNumberVin', 'deliveryDate', 'shipmentDate']);
    setShowModal(isOpen);
  }, [isOpen]);

  function updateDateValue(value, element, focused = null) {
    const isFocused = focused === null ? focus[element] : focused;
    clearErrors(['shipmentDate', 'deliveryDate']);
    if (element === 'shipmentDate' && value) {
      setErrorShipmentDate(null);
    }
    if (element === 'deliveryDate' && value) {
      setErrorDeliveryDate(null);
    }
    setFocus({ [element]: isFocused });
    const newValues = {
      ...values,
      [element]: value,
    };

    return setValues(newValues);
  }

  useEffect(() => {
    if (selectedVehicle?.original && isOpen) {
      const updatedvalues = {
        ...selectedVehicle?.original,
        estimatedShipmentDate: selectedVehicle?.estimatedShipmentDate,
        rpnNumber: selectedVehicle?.rpnNumber,
      };
      setValues(updatedvalues);
    }
  }, [isOpen]);

  const handleSubmit = () => {
    errorUpdatingTo('');
    clearErrors(['serialNumberVin', 'deliveryDate', 'shipmentDate']);
    const errors = [];
    if (['CC', 'DD'].includes(values.statusCode)) {
      errors.push(
        ...validateValue('serialNumberVin', values.serialNumberVin, false)
          .errors,
      );

      if (values.statusCode === 'DD') {
        if (!values.deliveryDate) {
          setErrorDeliveryDate(feedback.deliveryDate[0]);
        }
        errors.push(
          ...validateValue('deliveryDate', values.deliveryDate, false).errors,
        );
      }

      if (values.statusCode === 'CC') {
        if (!values.shipmentDate) {
          setErrorShipmentDate(feedback.shipmentDate[0]);
        }
        errors.push(
          ...validateValue('shipmentDate', values.shipmentDate, false).errors,
        );
      }
    }

    if (values.shipmentDate) {
      errors.push(
        ...validateValue('shipmentDate', values.shipmentDate, false).errors,
      );
    }
    if (values.deliveryDate) {
      errors.push(
        ...validateValue('deliveryDate', values.deliveryDate, false).errors,
      );
    }
    setErrors(errors);
    if (errors.some((err) => err.visibility === 'error')) {
      return;
    }

    if (onSubmit && typeof onSubmit === 'function') {
      onSubmit(values);
    }
  };

  const handleCancel = () => {
    if (onCancel && typeof onCancel === 'function') {
      onCancel();
    }
  };

  function updateValue(e, element, focused = null) {
    if (!e.target) return false;
    const isFocused = focused === null ? focus[element] : focused;

    if (element === 'statusCode' && e.target.value !== 'CC') {
      setErrorShipmentDate(null);
    }
    if (element === 'statusCode' && e.target.value !== 'DD') {
      setErrorDeliveryDate(null);
    }
    setFocus({ [element]: isFocused });
    const newValues = {
      ...values,
      [element]: e.target.value,
    };
    return setValues(newValues);
  }

  return (
    <>
      {showModal && (
        <div className="afp-modal-overlay modalContainer" data-testid={testId}>
          <Modal
            title={title}
            className="add-comment-modal"
            onClose={handleCancel}
            actions={
              <div className="add-comment-button-row">
                <Button
                  type="button"
                  variant="unstyled"
                  data-testid={`${testId}-cancel-button`}
                  onClick={handleCancel}
                  label="Cancel"
                />
                <Button
                  type="button"
                  variant="default"
                  className="margin-left-3"
                  data-testid={`${testId}-submit-button`}
                  onClick={handleSubmit}
                  label="Submit"
                />
              </div>
            }
          >
            <Link className="rpn-number" to={`/order?orderId=${orderId}`}>
              {values.rpnNumber}
            </Link>
            {(Object.keys(visibility).some(
              (key) => visibility[key] === 'error',
            ) || errorUpdating) && (
              <Alert type="error" slim>
                { errorUpdating || 'Please address errors before saving your update.'}
              </Alert>
            )}
            <Alert type="info">
              If you need to modify anything other than the status information
              provided here, please initiate an order modification by{' '}
              <Link to={`/order?orderId=${orderId}`}>
                contacting the Vehicle Buying Team here
              </Link>
              .
            </Alert>
            <form
              className="agencyInformationForm"
              data-testid="agencyInformationForm"
            >
              <fieldset
                className="usa-fieldset"
                key="agencyInformationForm-Bureau-Field"
              >
                <div key="3" className="grid-container  padding-0">
                  <div className="form-section">
                    <div className="grid-row flexed">
                      <div className="grid-col-5 padding-x-1">
                        <div className="usa-form-group" data-testid="von">
                          <label className="usa-label " htmlFor="von">
                            Vehicle order number (VON)
                          </label>
                          <Textbox
                            aria-label="Enter agency order number"
                            id="vehicleOrderNumber"
                            data-testid="agency-order-num-field"
                            value={values.vehicleOrderNumber}
                            key="vehicleOrderNumber"
                            onChange={(e) =>
                              updateValue(e, 'vehicleOrderNumber', true)
                            }
                            onFocus={(e) =>
                              updateValue(e, 'vehicleOrderNumber', true)
                            }
                            onBlur={(e) => {
                              updateValue(e, 'vehicleOrderNumber', false);
                              // handleOnBlur();
                            }}
                            maxLength="8"
                          />
                          <div className="helper-text">
                            8 characters allowed
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="grid-row flexed">
                      <div className="grid-col-5 padding-x-1">
                        <div
                          className="usa-form-group"
                          data-testid="agencyOrderNumber"
                        >
                          <label
                            className="usa-label "
                            htmlFor="serialNumberVin"
                          >
                            Vehicle identification number (VIN)
                          </label>
                          <div>
                            <FieldFeedback
                              text={feedbackText.serialNumberVin}
                              visible={visibility.serialNumberVin}
                              id="vinFeedback"
                              applyTo={(child) => child.props.type === 'text'}
                              isVehicleContext={false}
                            >
                              <Textbox
                                aria-label="Enter VIN number"
                                id="serialNumberVin"
                                data-testid="vin-num-field"
                                value={values.serialNumberVin}
                                key="serialNumberVin"
                                onChange={(e) =>
                                  updateValue(e, 'serialNumberVin', true)
                                }
                                onFocus={(e) =>
                                  updateValue(e, 'serialNumberVin', true)
                                }
                                onBlur={(e) => {
                                  updateValue(e, 'serialNumberVin', false);
                                  // handleOnBlur();
                                }}
                                maxLength="17"
                              />
                            </FieldFeedback>
                          </div>
                          <div className="helper-text">
                            17 characters allowed
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="grid-row flexed">
                      <div className="grid-col-5 padding-x-1">
                        <div
                          className="usa-form-group"
                          data-testid="agencyOrderNumber"
                        >
                          <label className="usa-label" htmlFor="statusCode">
                            Vehicle status
                          </label>
                          <div>
                            <SelectDropdown
                              id="statusCode"
                              data-testid="signal-code-select"
                              onChange={(e) => updateValue(e, 'statusCode')}
                              // onBlur={handleOnBlur}
                              value={values.statusCode}
                              options={[
                                {
                                  label: 'Ordered',
                                  value: 'SP',
                                },
                                {
                                  label: 'In Production',
                                  value: 'IN',
                                },
                                {
                                  label: 'Shipped - Requires VIN',
                                  value: 'CC',
                                },
                                {
                                  label: 'Delivered - Requires VIN',
                                  value: 'DD',
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="grid-row">
                      <div className="grid-col-4 padding-x-1">
                        <label
                          className="usa-label"
                          htmlFor="estimatedShipmentDate"
                        >
                          Estimated shipment date
                        </label>
                        <p
                          id="estimatedShipmentDate"
                          data-testid="estimatedShipmentDate"
                          key="estimatedShipmentDate"
                        >
                          {formatDate(values.estimatedShipmentDate)}
                        </p>
                      </div>
                      <div className="grid-col-4 padding-x-1">
                        <label className="usa-label" htmlFor="shipmentDate">
                          Actual shipment date
                        </label>
                        <div>
                          <FieldFeedback
                            text={feedbackText.shipmentDate}
                            visible={visibility.shipmentDate}
                            id="shipmentDateFeedback"
                            applyTo={(child) => child.props.type === 'text'}
                            isVehicleContext={false}
                          >
                            <DatePicker
                              id="shipmentDate"
                              name="shipmentDate"
                              data-testid="shipmentDate"
                              defaultValue={formattedDate(values.shipmentDate)}
                              hint="mm/dd/yyyy"
                              onChange={(e) =>
                                updateDateValue(e, 'shipmentDate')
                              }
                              errorMessage={errorShipmentDate}
                              // aria-invalid={fieldValueState.errorMessage ? 'true' : 'false'}
                            />
                          </FieldFeedback>
                        </div>
                      </div>
                      <div className="grid-col-3 padding-x-1">
                        <label className="usa-label" htmlFor="deliveryDate">
                          Delivery date
                        </label>

                        <div>
                          <FieldFeedback
                            text={feedbackText.deliveryDate}
                            visible={visibility.deliveryDate}
                            id="deliveryDateFeedback"
                            applyTo={(child) => child.props.type === 'text'}
                            isVehicleContext={false}
                          >
                            <DatePicker
                              id="deliveryDate"
                              name="deliveryDate"
                              data-testid="deliveryDate"
                              defaultValue={formattedDate(values.deliveryDate)}
                              hint="mm/dd/yyyy"
                              onChange={(e) =>
                                updateDateValue(e, 'deliveryDate')
                              }
                              errorMessage={errorDeliveryDate}
                              // aria-invalid={fieldValueState.errorMessage ? 'true' : 'false'}
                            />
                          </FieldFeedback>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </Modal>
        </div>
      )}
    </>
  );
};

UpdateVehicleModal.propTypes = {
  orderId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  defaultValue: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  maxLength: PropTypes.number,
  'data-testid': PropTypes.string,
  selectedVehicle: PropTypes.instanceOf(Object),
  inputProps: PropTypes.instanceOf(Object),
  errorUpdating: PropTypes.string,
};

UpdateVehicleModal.defaultProps = {
  isOpen: false,
  onSubmit: null,
  onCancel: null,
  defaultValue: '',
  title: 'Update vehicle information',
  body: 'Your comment will be added to this timeline and be viewable by involved parties.',
  maxLength: 500,
  'data-testid': null,
  selectedVehicle: {},
  inputProps: {},
  errorUpdating:''
};

export default UpdateVehicleModal;
