import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './total-selling-price.scss';
import cn from 'classnames';
import { Button, useModal, connectModal } from '@gsa/afp-component-library';
import { GiCheckMark } from 'react-icons/gi';
import ArrowRightSvg from '@/assets/images/arrow-right.svg';
import { formatCurrency } from '@/utilities/CompareVehicleUtils.jsx';
import { getAfvIncremental } from '../../../../../utilities/TotalSellingPriceUtils.jsx';
import { setSelectedContract, setDealershipDeliveryAddress, setSelectedEngine } from "@/reducers/leasing.jsx";
import useLeasingNavigation from "@/hooks/useLeasingNavigation.jsx";
import useUpdateDraft from "../../../components/update-draft/update-leasing-draft.js";
import { useGetFundingAvailability } from "../../../../../requests/leasing.jsx";
import {LeasingSteps} from "../../../leasing-consts.js";
import FundingModal from "./funding-modal.jsx";
import OptionsUnavailableModal from './option-unavailable-modal.jsx'
import {setAddOptionErrors, setDisplayCalculatePriceModal} from "../../../../../reducers/leasing.jsx";
import { OPTION_OPTION_TYPE, OPTION_QUANTITY_REQUIRED_CODE } from '../../../../../utilities/CompareVehicleUtils.jsx';

const TotalSellingPriceSection = ({
  contracts,
  lowestBidData,
  networkCostBreakDownData,
}) => {
  const dispatch = useDispatch();
  const displayFundingModal = useModal();
  const displayUnavailableModal = useModal();
  const DisplayFundingModal = connectModal(FundingModal);
  const DisplayUnavailableModal = connectModal(OptionsUnavailableModal);
  const [insufficientAFVFunds, setInsufficientAFVFunds] = useState(false);
  const [currentAvailableAFVFunds, setCurrentAvailableAFVFunds] = useState(0.0);
  const [unavailableOptions, setUnavailableOptions] = useState([])
  const [tempState, setTempState] = useState(null)
  const { handleLeasingNavigation } = useLeasingNavigation();
  const [updateDraft] = useUpdateDraft();
  const [getLeasingAvailability, {loading}] = useGetFundingAvailability();
  const {account, isCalculatePriceDisabled} = useSelector((state) => state.leasingReducer);
  const { compareSelect: { selectedContract, addOptions, selectedOptions, simplifiedContractsData}, shipmentCountry } = useSelector((state) => state.leasingReducer);
  const activateLowestBid = (contract) => {
    return contract?.contractLineId === lowestBidData?.contractLineId
      ? 'lowest-bid'
      : '';
  };

  useEffect(() => {
    const input = {
      extendedProps: { totalSellingPrice: null },
    };
    updateDraft(input, LeasingSteps.COMPARE_AND_SELECT);
  },[])

  const getSelectedNotAvailableOptions = (selectedContractCostBreakdown) => {
    const contractsToReturn = [];
    if (selectedContractCostBreakdown) {
        selectedContractCostBreakdown.perOrderOptions.forEach((option) => {
            if (option.optionType === OPTION_OPTION_TYPE.NOT_AVAILABLE) {
                contractsToReturn.push(option);
            }
        });
        selectedContractCostBreakdown.perVehicleOptions.forEach((option) => {
            if (option.optionType === OPTION_OPTION_TYPE.NOT_AVAILABLE) {
                contractsToReturn.push(option);
            }
        });
    }
    if (addOptions && addOptions.length > 0) {
      addOptions.forEach((option) => {
            if (option.optionType === OPTION_OPTION_TYPE.NOT_AVAILABLE) {
                contractsToReturn.push(option.options[0]);
            }
        });
    }
    return contractsToReturn;
};

  const handleSelectButton = async (unBuildableOption, contract) => {
    let hasErrors = false;
    const optionsErrors = {};
    selectedOptions.every((option) => {
      const hasNumInput =
          option.quantityRequiredCode === OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE ||
          option.quantityRequiredCode === OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER;

      if(option.isChecked && (!option.optionValue || option.optionValue === '' || option.hasError) && hasNumInput) {
        optionsErrors[option.optionCode] = true;
        hasErrors = true;
      }
    });

    const errorCount = Object.values(optionsErrors).filter(
        (entry) => {
          return entry;
        },
    ).length;

    if (hasErrors) {
      dispatch(setAddOptionErrors(errorCount));
      return;
    }
    const foundCostBreakDown = networkCostBreakDownData.find(
      (cb) => cb.contractLineId === contract.contractLineId,
    );

    const agencyIndicator = (account?.customer?.customerBureau?.agencyIndicatorCode === null || account.customer?.customerBureau?.agencyIndicatorCode === '') ? 'N/A' : account?.customer?.customerBureau?.agencyIndicatorCode;

    const res = await getLeasingAvailability({ variables: { agencyCode: account?.customer?.customerAgency?.id, agencyIndicator}});
    const hasSufficientAFVFunds = (res?.data?.currentAvailableAFVFunds >= (foundCostBreakDown.afvTotal * foundCostBreakDown.quantity));
    setInsufficientAFVFunds(!hasSufficientAFVFunds);
    setCurrentAvailableAFVFunds(res?.data?.currentAvailableAFVFunds);
    if (
      res?.data?.currentAvailableFunds >
      foundCostBreakDown?.totalSellingPrice && (hasSufficientAFVFunds)
    ) {
      if (
        selectedContract?.contractLineId !== foundCostBreakDown?.contractLineId
      ) {
        setDealershipDeliveryAddress({});
      }

      const afvIncremental =  getAfvIncremental({afvTotal:foundCostBreakDown?.afvTotal,quantity:  foundCostBreakDown?.quantity});
      const contractInfo = simplifiedContractsData.find((contract) => contract.contractLineId === foundCostBreakDown.contractLineId);
      const engines = contractInfo?.engines || [];
      const clarifications = contractInfo?.clarifications || [];
      const optionCodes = selectedOptions.map((opt) => opt.optionCode);
      const activeEngineCode = optionCodes
        .filter(x => engines.map(y => y.engineCode)
        .includes(x))?.[0] || 'BASEENGINE';
      dispatch(setSelectedContract({...foundCostBreakDown, afvIncremental,
        clarifications,
        makeName: contract?.makeName,
        modelName: contract?.modelName,
        vendorBusinessClassName: contract?.vendorBusinessClassName,
        orderEndDate: contract?.orderEndDate,
        vendorName: contract?.vendorName,
        shipmentDays: contract?.shipmentDays,
        contractLineId: contract?.contractLineId,
        contractHeaderVersionNumber: contract?.contractHeaderVersionNumber,
        contractLineVersionNumber: contract?.contractLineVersionNumber,
       }));

      const selectedEngine = engines.find(x => x.engineCode === activeEngineCode) || {};
      dispatch(setSelectedEngine(selectedEngine));

      const input = {
        extendedProps: {
          totalSellingPrice: foundCostBreakDown.totalSellingPrice,
          afvIncremental,
          contractLineId: foundCostBreakDown.contractLineId,
          contractHeaderVersionNumber: contract?.contractHeaderVersionNumber,
          contractLineVersionNumber: contract?.contractLineVersionNumber,
        },
        clientData: {
          clientState: {
            selectedContract: foundCostBreakDown,
          },
        },
        vehicle: {
          ...(selectedEngine?.fuelType && {fuelCode: selectedEngine?.fuelType}),
          ...(selectedEngine?.range && {totalRangeMiles: selectedEngine?.range}),
          ...(selectedEngine?.convMpgCity && {convMpgCity: selectedEngine?.convMpgCity}),
          ...(selectedEngine?.convMpgHighway && {convMpgHighway: selectedEngine?.convMpgHighway}),
          ...(selectedEngine?.convMpgCombined && {convMpgCombined: selectedEngine?.convMpgCombined}),
          ...(selectedEngine?.convGpm && {convGpm: selectedEngine?.convGpm}),
          ...(selectedEngine?.fuelType && {altFuelCode: selectedEngine?.fuelType}),
          ...(selectedEngine?.altMpgCity && {altMpgCity: selectedEngine?.altMpgCity}),
          ...(selectedEngine?.altMpgHighway && {altMpgHighway: selectedEngine?.altMpgHighway}),
          ...(selectedEngine?.altMpgCombined && {altMpgCombined: selectedEngine?.altMpgCombined}),
          ...(selectedEngine?.altGpm && {altGpm: selectedEngine?.altGpm}),
          ...(selectedEngine?.rangeElectric && {elecRangeMiles: selectedEngine?.rangeElectric}),
          ...(selectedEngine?.elecMpgCity && {elecMpgCity: selectedEngine?.elecMpgCity}),
          ...(selectedEngine?.elecMpgHighway && {elecMpgHighway: selectedEngine?.elecMpgHighway}),
          ...(selectedEngine?.elecMpgCombined && {elecMpgCombined: selectedEngine?.elecMpgCombined}),
        }
      };

      const unavailableOpt = getSelectedNotAvailableOptions(foundCostBreakDown);
      setUnavailableOptions(unavailableOpt)
      if(unavailableOpt?.length){
        setTempState(input)
        displayUnavailableModal.openModal();
      }  else {
        updateDraft(input, LeasingSteps.PAINT_AND_GRAPHICS).then((data) => {
          if (data) handleLeasingNavigation(LeasingSteps.PAINT_AND_GRAPHICS);
        });
      }
    } else {
      displayFundingModal.openModal();
    }
  };

  const handleContinue = () => {
    if(tempState){
      updateDraft(tempState, LeasingSteps.PAINT_AND_GRAPHICS).then((data) => {
        if (data) handleLeasingNavigation(LeasingSteps.PAINT_AND_GRAPHICS);
      });
    }
  }
  return (
    <>
      <div className="afp-store-total-selling-price">
        <table className="usa-table full-width-table" role="presentation">
          <tbody>
            <tr>
              <td
                data-testid="total-sale-price-cell"
                className="total-sale-price-title-cell"
              >
                Total selling price:
              </td>
              {networkCostBreakDownData?.map((contract) => {
                const contractFragmentKey = `${contract?.contractLineId}`;
                const unBuildableInfo = contract?.isUnBuildable;
                return (
                  <React.Fragment key={contractFragmentKey}>
                    <td
                      data-testid={`${contract?.contractLineId}-total-sale-price`}
                      key={`${contract?.contractLineId}-total-sale-price`}
                      className={cn(
                        'contract-cell',
                        activateLowestBid(contract),
                      )}
                    >
                      {formatCurrency(contract?.totalSellingPrice)}{' '}
                      {unBuildableInfo ? '*' : ''}
                    </td>
                  </React.Fragment>
                );
              })}
            </tr>
            <>
              <tr>
                <td
                  data-testid="start-requisition"
                  className="total-sale-price-title-cell requisition"
                >
                  Start your Requisition
                  <img
                    className="requisition-arrow"
                    src={ArrowRightSvg}
                    alt="requisition direction icon"
                  />
                </td>
                {contracts?.map((contract) => {
                  const unBuildableOption =
                    networkCostBreakDownData &&
                    networkCostBreakDownData.find((item) => {
                      return item.contractLineId === contract?.contractLineId;
                    });

                  return (
                    <td
                      data-testid={`${contract?.contractLineId}-select-vehicle`}
                      key={`select-vehicle-${contract?.contractLineId}`}
                      className={cn(
                        'contract-cell',
                        activateLowestBid(contract),
                        'requisition-cell',
                      )}
                    >
                      <Button
                        data-testid={`${contract?.contractLineId}-select-model`}
                        className="req-select-button tooltip margin-0"
                        type="button"
                        variant="outline"
                        onClick={() => {
                            if(!isCalculatePriceDisabled){
                                dispatch(setDisplayCalculatePriceModal(true));
                                return;
                            }
                          handleSelectButton(unBuildableOption, contract);
                        }}
                        label="Select This Model"
                      />
                    </td>
                  );
                })}
              </tr>
              {contracts[0]?.contractLineId ===
                lowestBidData?.contractLineId && (
                <tr>
                  <td
                    aria-label="td"
                    data-testid="start-requisition"
                    className="total-sale-price-title-cell requisition"
                  />
                  <td
                    data-testid={`${contracts[0]?.contractLineId}-pre-selected-vendors`}
                    className="pre-selected-vendors"
                  >
                    <span className="selected-vendor-container lowest-bid-container">
                      <GiCheckMark />
                      <span className="selected-vendor-title">LOWEST BID</span>
                    </span>
                  </td>
                </tr>
              )}
            </>
          </tbody>
        </table>
      </div>

      <DisplayFundingModal
        isOpen={displayFundingModal.isOpen}
        handleClose={() => displayFundingModal.closeModal()}
        isInsufficientAFV={insufficientAFVFunds}
        remainingAFVFunding={currentAvailableAFVFunds}
      />
      <DisplayUnavailableModal
        isOpen={displayUnavailableModal.isOpen}
        handleClose={() => displayUnavailableModal.closeModal()}
        handleContinue={handleContinue}
        unavailableOptions={unavailableOptions}
      />
    </>
  );
};

export default TotalSellingPriceSection;
