import { gql } from '@apollo/client';

export const CREATE_REQUISITION_DRAFT_NEW = gql`
  mutation CreateRequisitionDraft(
    $createInput: RequisitionCreatePropsInput!
    $updateInput: RequisitionUpdatePropsInput
  ) {
    createRequisitionDraft(
      createInput: $createInput
      updateInput: $updateInput
    ) {
      requisitionStatus
      requisitionId
      friendlyName
      requisitionStatus
      contractLineId
      standardItemId
      accountingClassificationReferenceNumber
      additionalRequirements {
        priceEstimate
      }
      createdByInfo {
        id
        firstName
        lastName
        email
        phoneNumber
        phoneNumberExt
      }
      agencyCode
      agencyReferenceNumber
      boac
      bureauCode
      clientData {
        clientState
        selectedOptions
        activeContracts
      }
      agencyInfo {
        name
      }
      mailingAddress {
        addressLine1
        addressLine2
        city
        countryCode
        zipcode
      }
      deliveryAddress {
        addressLine1
        addressLine2
        city
        countryCode
        zipcode
        deliveryDealership {
          dealershipDeliveryCode
          id
          dealershipId
          dealershipDeliveryCode
          makeCode
          email
          phone
          address1
          address2
          city
          stateId
          countryId
          postalCode
          dealerName
        }
      }
    }
  }
`;

export const UPDATE_REQUISITION_DRAFT_NEW = gql`
  mutation UpdateRequisitionDraft(
    $updateInput: RequisitionUpdatePropsInput!
    $requisitionId: String!
  ) {
    updateRequisitionDraft(
      updateInput: $updateInput
      requisitionId: $requisitionId
    )
  }
`;

/* 

Data in each stepper page :

Header {
    agencyCode:
    bureauCode:

}

1. Compare and Select  
     {
        standardItemId: 
        contractLineId:
        clientState.selectedOptions:
        makeCode: 
        modelCode: 
        modelYear: 
        baseShipmentDays:
        additionalShipmentDays:
        collisions: 
        contract data: 
        deliveryDays:
        calculatedPrice
     }
2. Paint and Color
     {
       vehilce color
       colorOptions ?
     }
3. Review and Build  {
        options
        prices

}     
4.  Agency Information : {
    boac:
    aac:
    agencyPoc: 
    agencyOrderNumber: 
    tcn:
    nationalstocknumber: NSN
    requisitionNumber:
    registrationNumber:
    finFundCode?: string;
    finServiceCode?: string;
    finSignalCode?: string;
    finSupplementaryAddress?: string;
    MarkForInformation:
    mailingAddress
    DeliveryAddress

}    

*/
