import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Link,
  useModal,
  connectModal,
  Modal,
} from '@gsa/afp-component-library';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import OrderStatusTag from '../../my-orders/OrderStatusTag';
import OrderAddress from './OrderAddress';
import MVDOAttachmentReprint from '../../requisitions-and-orders/widgets/AttachmentDownload/MVDOAttachmentReprint';
import { REQUISITION_TYPE } from '../../non-standard-purchase/constants';
import OrderMessageLines from './OrderMessageLines';
import { UserRoles } from '../../../constants/user-constants';
import { isInternalUser } from '../../../utilities/authorization';
import {
  emDashUnicode,
  OrderTelematicsStatus,
  StoreOperations,
  StoreSubjects,
} from '../../../constants/constants';
import { TRANSACTION_TYPES } from '../../../utilities/CompareVehicleUtils';
import { getBoacVar, getOrderRequisitionLink } from './orderDetailsUtil';
import { VALIDATE_BOAC } from '../../../services/data-layer';
import { getCurrentFiscalYear } from '../../VehicleRequisition/utils/VehicleRequisitionUtils';
import { formatCurrency } from '../../../utilities/CompareVehicleUtils';

const renderModHistory = (modHistory) => {
  return modHistory.map((mod) => (
    <div className="order-mod-row">
      <div className="mod-name">{mod?.orderModName}</div>
      <div className="mod-date">
        {Moment.utc(mod?.updatedAt).format('MM/DD/YYYY')}
      </div>
      <div className="mod-details">
        <Link
          href={`/order-modification-details?modificationId=${mod?.orderModificationId}`}
        >
          View details
        </Link>
      </div>
    </div>
  ));
};
// eslint-disable-next-line react/prop-types
const DisplayModificationHistoryModal = ({
  handleClose,
  rpnNumber,
  modificationHistory,
}) => {
  return (
    <Modal
      data-testid="display-order-mod-history-modal"
      className="order-mod-history-modal"
      title={<div className="modal-title">Order modification history</div>}
      onClose={() => {
        handleClose();
      }}
      actions={
        <div className="areq-print-preview-button-row">
          <Button
            type="button"
            variant="primary"
            data-testid="order-mod-history-modal-close-button"
            onClick={handleClose}
            label="Close"
          />
        </div>
      }
    >
      <div>
        <div className="order-mod-history-container">
          <div className="order-mod-history-title">{rpnNumber}</div>
          <div>{renderModHistory(modificationHistory, handleClose)}</div>
        </div>
      </div>
    </Modal>
  );
};

const OrderDetails = ({
  orderData,
  contractModificationLine,
  deliveryDealershipAddress,
  canViewLeasing,
  orderModHistory,
}) => {
  const {
    deliveryAddress,
    deliveryContact,
    requisitionerAddress,
    requisitionerContact,
    licensePlateDeliveryAddress,
    licensePlateDeliveryContact,
    mailingAddress,
    mailingContact,
    scheduleLine,
    contractNumber,
    boac,
    finSignalCode,
    finSupplementaryAddress,
    agencyCode,
    bureauCode,
  } = orderData;
  const ability = useAppAbility();
  const { currentUser } = useCurrentUser();

  // Get TAS
  const boacVar = getBoacVar(finSignalCode, finSupplementaryAddress, boac);
  const { data: tasData } = useQuery(VALIDATE_BOAC, {
    skip: !boacVar,
    variables: {
      boac: boacVar,
      agencyCode,
      bureauCode,
      fiscalYear: getCurrentFiscalYear(),
      salesGroup: 'TM12',
    },
  });

  const { contractLineFuel } = contractModificationLine?.equipment
    ? contractModificationLine?.equipment[0]
    : {};
  const viewModificationHistoryModal = useModal();
  const ViewModificationHistoryModal = connectModal(
    DisplayModificationHistoryModal,
  );

  const { contractMod } = contractModificationLine;
  const getValueOrEmptyString = (value) => {
    if (value) {
      return value;
    }
    if (value === 0) {
      return '0';
    }
    return emDashUnicode;
  };

  const contractShipmentDate = () => {
    const estimatedDeliverDays = getValueOrEmptyString(
      orderData.baseShipmentDays + orderData.additionalShipmentDays,
    );

    const orderAwardedDate = getValueOrEmptyString(
      orderData?.submittedToVendorAt ?? orderData?.createdAt,
    );

    const formatDate = Moment(orderAwardedDate).format('MM/DD/YYYY');

    const getContractShipmentDate = Moment(formatDate)
      .add(estimatedDeliverDays, 'days')
      .format('MM/DD/YYYY');

    if (!getContractShipmentDate) {
      return emDashUnicode;
    }

    return getContractShipmentDate;
  };
  const getDeliveryAddressTitle = () => {
    if (orderData?.isDealerDelivery) {
      return 'DEALERSHIP DELIVERY ADDRESS';
    }
    return 'DELIVERY ADDRESS';
  };

  const getAddressCountryCode = (addressInfo) => {
    if (
      addressInfo?.isMilitary ||
      addressInfo?.isDomestic ||
      addressInfo?.countryCode === 'US'
    ) {
      return null;
    }
    return addressInfo?.countryCode;
  };

  const isContractingOfficerAndCanUpdateOrder = useMemo(() => {
    const isContractingOfficer = currentUser?.roles?.some(
      (role) => role.name === UserRoles.CONTRACTING_OFFICER,
    );

    if (!isContractingOfficer) {
      return false;
    }

    if (!ability) {
      return false;
    }

    return ability.can(StoreOperations.Update, StoreSubjects.Order);
  }, [currentUser]);

  const canViewOrder = useMemo(() => {
    if (!ability) {
      return false;
    }

    return ability.can(StoreOperations.View, StoreSubjects.Order);
  }, [ability]);

  const getTempContractShipmentDate = (row) => {
    const daysOffset =
      (row.baseShipmentDays ? row.baseShipmentDays : 0) +
      (row.additionalShipmentDays ? row.additionalShipmentDays : 0);
    return Moment(row.createdAt).add(daysOffset, 'days').format('MM/DD/YYYY');
  };
  // AFP-26244 not required as per this story
  /*  const getCreatedByInfo = () => {
      if (requisitionActivities && requisitionActivities.length) {
        const foundSubmittedRecord = requisitionActivities.find(
          (activity) => activity?.type?.name === 'REQUISITION_CREATED',
        );

        return (
          <>
            <p
              className="text-normal"
              data-testid="order-info-requisition-creator-name"
            >
              <GoToLink
                href={`mailto:${foundSubmittedRecord?.createdUserInfo?.email}`}
              >
                {foundSubmittedRecord?.createdUserInfo?.fullName}
              </GoToLink>
            </p>
            <p>
              {foundSubmittedRecord?.createdDateTime
                ? Moment(foundSubmittedRecord?.createdDateTime).format(
                    'MM/DD/YYYY',
                  )
                : ''}{' '}
              &#8226;{' '}
              {foundSubmittedRecord?.createdDateTime
                ? Moment(foundSubmittedRecord?.createdDateTime).format('hh:mm A')
                : ''}{' '}
              ET
            </p>
          </>
        );
      }
      return null;
    }; */

  /*
    const getSubmittedByInfo = () => {
      if (requisitionActivities && requisitionActivities.length) {
        const foundSubmittedRecord = requisitionActivities.find(
          (activity) => activity?.type?.name === 'REQUISITION_CREATED',
        );
        const foundResubmittedRecord = requisitionActivities.find(
          (activity) => activity?.type?.name === 'REQUISITION_RESUBMITTED',
        );
        if (foundResubmittedRecord) {
          return (
            <>
              <p
                className="text-normal"
                data-testid="order-info-requisition-creator-name"
              >
                <GoToLink
                  href={`mailto:${foundResubmittedRecord.createdUserInfo?.email}`}
                >
                  {foundResubmittedRecord.createdUserInfo?.firstName}{' '}
                  {foundResubmittedRecord.createdUserInfo?.lastName}
                </GoToLink>
              </p>
              <p className="text-normal">
                {Moment(foundResubmittedRecord.createdDateTime).format(
                  'MM/DD/YYYY',
                )}{' '}
                &#8226;{' '}
                {Moment(foundResubmittedRecord.createdDateTime).format('hh:mm A')}{' '}
                ET
              </p>
            </>
          );
        }

        return (
          <>
            <p
              className="text-normal"
              data-testid="order-info-requisition-creator-name"
            >
              <GoToLink
                href={`mailto:${foundSubmittedRecord?.createdUserInfo?.email}`}
              >
                {foundSubmittedRecord?.createdUserInfo?.fullName}
              </GoToLink>
            </p>
            <p>
              {Moment(foundSubmittedRecord?.createdDateTime).format('MM/DD/YYYY')}{' '}
              &#8226;{' '}
              {Moment(foundSubmittedRecord?.createdDateTime).format('hh:mm A')} ET
            </p>
          </>
        );
      }
      return null;
    };
  */

  const contractAwardedDate = Moment(
    contractMod?.contract?.awardedDate ?? null,
  );
  const contractAwardedDateFormatted = contractAwardedDate.isValid()
    ? contractAwardedDate.format('MM/DD/YYYY')
    : '';

  return (
    <div className="order-details">
      <h3 className="font-sans-lg text-bold order-details__overview-title">
        Overview
      </h3>
      <div className="order-details__overview-section">
        <div className="order-details__overview-section__content-container">
          <div className="order-details__overview-section__content">
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Order status
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  data-testid="order-info-status"
                  className="order-details-container__status-tag text-style"
                >
                  <OrderStatusTag value={orderData.orderStatus} />
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Order number
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-rpn-number"
                >
                  {orderData.rpnNumber}
                </span>
                {'  ('}
                <span className="text-bold">Version :</span>
                <span className="text-normal">
                  {' '}
                  {orderData.version}
                  {')'}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Requisition number
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-requisition-number"
                >
                  {orderData?.requisitionId ? (
                    <RouterLink
                      className="order-requisition-link"
                      to={getOrderRequisitionLink(orderData)}
                    >
                      {orderData?.requisitionNumber}
                    </RouterLink>
                  ) : (
                    <span>{orderData?.requisitionNumber}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                DO UPIID
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-upiid-number"
                >
                  {orderData.upiidNumber}
                </span>
              </div>
            </div>
            {orderData?.requisitionType === REQUISITION_TYPE.STANDARD_ORDER && (
              <div className="order-details__overview-section__content__data">
                <div className="order-details__overview-section__content__data__title text-bold">
                  Standard Item Number
                </div>
                <div className="order-details__overview-section__content__data__value">
                  <span className="text-normal" data-testid="order-info-sin">
                    {orderData.standardItemCode}
                  </span>
                </div>
              </div>
            )}
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                {orderData?.requisitionType === REQUISITION_TYPE.STANDARD_ORDER
                  ? 'Standard Item description'
                  : 'Non-Standard Item description'}
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-sin-description"
                >
                  {orderData.standardItemDescription || emDashUnicode}
                </span>
              </div>
            </div>
          </div>
          <div className="order-details__overview-section__content">
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Quantity of vehicles
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-vehicle-quantity"
                >
                  {orderData?.orderVehicles?.length}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Shipment location
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-shipment-location"
                >
                  {orderData.isExport ? 'Export' : 'Domestic'}
                </span>
              </div>
            </div>

            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Date placed
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span className="text-normal">
                  {orderData?.submittedToVendorAt ? (
                    <>
                      {Moment.utc(orderData?.submittedToVendorAt).format(
                        'MM/DD/YYYY',
                      )}
                    </>
                  ) : (
                    <>{emDashUnicode}</>
                  )}
                  {orderData?.submittedToVendorAt && (
                    <>
                      {' '}
                      &#8226;
                      {` ${Moment.utc(orderData?.submittedToVendorAt).format(
                        'hh:mm A',
                      )} ET`}
                    </>
                  )}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Contract shipment date
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span className="text-normal">
                  {orderData?.shipmentDate
                    ? Moment.utc(orderData?.shipmentDate).format('MM/DD/YYYY')
                    : getTempContractShipmentDate(orderData)}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Original estimated delivery days
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span className="text-normal">
                  {getValueOrEmptyString(
                    orderData.baseShipmentDays +
                      orderData.additionalShipmentDays,
                  )}
                </span>
              </div>
            </div>

            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Motor Vehicle Delivery Order
              </div>
              <div className="order-details__overview-section__content__data__value">
                <MVDOAttachmentReprint
                  displayName="View PDF"
                  orderId={orderData?.orderId}
                />
              </div>
            </div>

            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Order Modifications
              </div>
              {orderModHistory?.length > 0 ? (
                <div className="order-details__overview-section__content__data__value">
                  <span className="text-normal">{orderModHistory?.length}</span>{' '}
                  {'  '}
                  <Button
                    type="button"
                    variant="unstyled"
                    data-testid="order-mod-history-view-button"
                    onClick={() => {
                      viewModificationHistoryModal.openModal();
                    }}
                    label="View"
                  />
                </div>
              ) : (
                emDashUnicode
              )}
            </div>
          </div>
        </div>
        <div className="order-details__overview-section__sub-section-container">
          <h4 className="font-sans-xs text-bold order-details__overview-section__sub-section-title">
            VEHICLE INFORMATION
          </h4>
        </div>
        <div className="order-details__overview-section__content-container">
          <div className="order-details__overview-section__content">
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Vendor
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span className="text-normal" data-testid="order-info-vendor">
                  {getValueOrEmptyString(
                    contractMod?.contract?.vendor?.vendorName,
                  )}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Make
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span className="text-normal" data-testid="order-info-make">
                  {getValueOrEmptyString(orderData?.makeInfo?.makeName)}
                </span>
              </div>
            </div>

            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Model
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span className="text-normal" data-testid="order-info-model">
                  {getValueOrEmptyString(orderData?.modelInfo?.modelName)}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Model year
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-model-year"
                >
                  {getValueOrEmptyString(orderData?.modelYear)}
                </span>
              </div>
            </div>
          </div>
          <div className="order-details__overview-section__content">
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                MPG
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-shipment-location"
                >
                  {getValueOrEmptyString(contractLineFuel?.convMpgCity)}/
                  {getValueOrEmptyString(contractLineFuel?.convMpgHighway)}/
                  {getValueOrEmptyString(contractLineFuel?.convMpgCombined)}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                gCO2/mile
              </div>
              <div className="order-details__overview-section__content__data__value">
                {getValueOrEmptyString(contractLineFuel?.convGpm)}
              </div>
            </div>
            {orderData?.transactionType === TRANSACTION_TYPES.LEASING && (
              <div className="order-details__overview-section__content__data">
                <div className="order-details__overview-section__content__data__title text-bold">
                  Telematics
                </div>
                <div className="order-details__overview-section__content__data__value">
                  {orderData.telematics
                    ? OrderTelematicsStatus[orderData.telematics]
                    : emDashUnicode}
                </div>
              </div>
            )}
          </div>
        </div>
        {/*        {orderData?.justification && (
          <>
            <div className="order-details__overview-section__sub-section-container">
              <h4 className="font-sans-xs text-bold order-details__overview-section__sub-section-title">
                JUSTIFICATION FOR NON-LOW PRICE SELECTION
              </h4>
            </div>
            <div className="order-details__non-low-price-justification-container">
              <div
                className="order-details__non-low-price-justification-container__text" // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: orderData?.justification,
                }}
              />
            </div>
          </>
        )} */}
      </div>
      {/* Message Line Section */}
      {(canViewOrder || isContractingOfficerAndCanUpdateOrder) && (
        <div className="order-details__overview-section__sub-section-container">
          <OrderMessageLines
            orderId={orderData.orderId}
            isEditAllowed={isContractingOfficerAndCanUpdateOrder}
          />
        </div>
      )}
      {orderData.transactionType === TRANSACTION_TYPES.LEASING &&
        canViewLeasing && (
          <>
            <h3 className="font-sans-lg text-bold order-details__overview-title">
              Leasing details
            </h3>
            <div className="order-details__overview-section">
              <div className="order-details__overview-section__content-container">
                <div className="order-details__overview-section__content">
                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      Leasing agency
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span
                        className="text-normal"
                        data-testid="order-info-requisition-number"
                      >
                        {orderData.leasingAgencyName || '--'}
                      </span>
                    </div>
                  </div>
                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      Leasing bureau
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span
                        className="text-normal"
                        data-testid="order-info-rpn-number"
                      >
                        {orderData.leasingBureauName || '--'}
                      </span>
                    </div>
                  </div>
                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      Leasing office
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span
                        className="text-normal"
                        data-testid="order-info-requisition-number"
                      >
                        {orderData.leasingOfficeName || '--'}
                      </span>
                    </div>
                  </div>
                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      Customer BOAC
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span
                        className="text-normal"
                        data-testid="order-info-requisition-number"
                      >
                        {orderData.leasingCustomerBOAC || '--'}
                      </span>
                    </div>
                  </div>
                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      Old license plate
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span
                        className="text-normal"
                        data-testid="order-info-upiid-number"
                      >
                        {orderData.oldLicencePlate || '--'}
                      </span>
                    </div>
                  </div>
                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      Agency order number
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span
                        className="text-normal"
                        data-testid="order-info-upiid-number"
                      >
                        {orderData.agencyOrderNumber || '--'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="order-details__overview-section__content">
                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      Customer account name
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span
                        className="text-normal"
                        data-testid="order-info-vehicle-quantity"
                      >
                        {orderData.leasingAccountName || '--'}
                      </span>
                    </div>
                  </div>
                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      Customer account number
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span
                        className="text-normal"
                        data-testid="order-info-shipment-location"
                      >
                        {orderData.customerNumber || '--'}
                      </span>
                    </div>
                  </div>

                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      Legacy customer number
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span className="text-normal">
                        {orderData.legacyCustomerNumber || '--'}
                      </span>
                    </div>
                  </div>
                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      Zone
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span className="text-normal">
                        {orderData.zone || '--'}
                      </span>
                    </div>
                  </div>

                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      FMC name
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span className="text-normal">
                        {orderData.fmcName || '--'}
                      </span>
                    </div>
                  </div>
                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      FSR name
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span className="text-normal">
                        {orderData.fsrFirstName || '--'}{' '}
                        {orderData.fsrLastName || '--'}
                      </span>
                    </div>
                  </div>
                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      Unit Price
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span className="text-normal">
                        {orderData?.orderVehicles[0]?.unitPriceToCustomer
                          ? formatCurrency(
                              orderData?.orderVehicles[0]?.unitPriceToCustomer,
                            )
                          : '--'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="font-sans-lg text-bold order-details__overview-title">
              Address details
            </h3>
            <div className="order-details__overview-section">
              <OrderAddress
                title="DELIVERY ADDRESS"
                entityName={deliveryAddress?.entityName}
                stateCode={deliveryAddress?.stateCode}
                countryCode={getAddressCountryCode(deliveryAddress)}
                firstName={deliveryContact?.firstName}
                lastName={deliveryContact?.lastName}
                testId="order-details-delivery-address"
                phoneNumber={deliveryContact?.phoneNumber}
                phoneCountryCode={deliveryContact?.phoneCountryCode}
                email={deliveryContact?.email}
                zipcode={deliveryAddress?.zipcode}
                line1={deliveryAddress?.addressLine1}
                line2={deliveryAddress?.addressLine2}
                city={deliveryAddress?.city}
              />
              <OrderAddress
                title="LICENSE PLATE DELIVERY ADDRESS"
                entityName={licensePlateDeliveryAddress?.entityName}
                stateCode={licensePlateDeliveryAddress?.stateCode}
                countryCode={getAddressCountryCode(licensePlateDeliveryAddress)}
                firstName={licensePlateDeliveryContact?.firstName}
                lastName={licensePlateDeliveryContact?.lastName}
                testId="order-details-requisitioning-address"
                phoneNumber={licensePlateDeliveryContact?.phoneNumber}
                phoneCountryCode={licensePlateDeliveryContact?.phoneCountryCode}
                email={licensePlateDeliveryContact?.email}
                zipcode={licensePlateDeliveryAddress?.zipcode}
                line1={licensePlateDeliveryAddress?.addressLine1}
                line2={licensePlateDeliveryAddress?.addressLine2}
                city={licensePlateDeliveryAddress?.city}
                state={licensePlateDeliveryAddress?.state}
              />
              <OrderAddress
                title="CUSTOMER AGENCY POINT OF CONTACT"
                entityName={orderData?.leasingAgencyName}
                stateCode={
                  orderData?.state_code || orderData.international_state_name
                }
                countryCode={getAddressCountryCode(mailingAddress)}
                firstName={orderData.first_name}
                lastName={orderData.last_name}
                testId="order-details-mailing-address"
                phoneNumber={orderData?.phoneNumber}
                phoneCountryCode={orderData?.phoneCountryCode}
                email={orderData?.email_address}
                zipcode={orderData?.postal_code}
                line1={orderData?.address1}
                line2={orderData?.address2}
                city={orderData?.city}
                state={licensePlateDeliveryAddress?.state_code}
              />
            </div>
          </>
        )}
      {orderData.transactionType !== TRANSACTION_TYPES.LEASING && (
        <>
          <h3 className="font-sans-lg text-bold order-details__overview-title">
            Agency information
          </h3>
          <div className="order-details__overview-section">
            <div className="order-details__overview-section__content-container">
              <div className="order-details__overview-section__content">
                {orderData?.agencyCode &&
                  orderData?.purchasingForAgencyCode && (
                    <>
                      <div className="order-details__overview-section__content__data">
                        <div className="order-details__overview-section__content__data__title text-bold">
                          Ordering Agency
                        </div>
                        <div className="order-details__overview-section__content__data__value">
                          <span
                            className="text-normal"
                            data-testid="ordering-agency-info-agency-name"
                          >
                            {getValueOrEmptyString(orderData.agencyInfo?.id)} -{' '}
                            {getValueOrEmptyString(orderData.agencyInfo?.name)}
                          </span>
                        </div>
                      </div>
                      <div className="order-details__overview-section__content__data">
                        <div className="order-details__overview-section__content__data__title text-bold">
                          Ordering Bureau
                        </div>
                        <div className="order-details__overview-section__content__data__value">
                          <span
                            className="text-normal"
                            data-testid="ordering-agency-info-bureau-name"
                          >
                            {getValueOrEmptyString(orderData.bureauInfo?.id)} -{' '}
                            {getValueOrEmptyString(orderData?.bureauInfo?.name)}
                          </span>
                        </div>
                      </div>
                      <div className="order-details__overview-section__content__data">
                        <div className="order-details__overview-section__content__data__title text-bold">
                          Ordering Office/Group
                        </div>
                        <div className="order-details__overview-section__content__data__value">
                          <span
                            className="text-normal"
                            data-testid="ordering-agency-info-office-name"
                          >
                            {getValueOrEmptyString(
                              orderData.officeInfo?.officeCode,
                            )}{' '}
                            -{' '}
                            {getValueOrEmptyString(
                              orderData.officeInfo?.officeName,
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="order-details__overview-section__content__data">
                        <div className="order-details__overview-section__content__data__title text-bold">
                          Receiving Agency
                        </div>
                        <div className="order-details__overview-section__content__data__value">
                          <span
                            className="text-normal"
                            data-testid="receiving-agency-info-agency-name"
                          >
                            {getValueOrEmptyString(
                              orderData.purchasingForAgencyInfo?.id,
                            )}{' '}
                            -{' '}
                            {getValueOrEmptyString(
                              orderData.purchasingForAgencyInfo?.name,
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="order-details__overview-section__content__data">
                        <div className="order-details__overview-section__content__data__title text-bold">
                          Receiving Bureau
                        </div>
                        <div className="order-details__overview-section__content__data__value">
                          <span
                            className="text-normal"
                            data-testid="receiving-agency-info-bureau-name"
                          >
                            {getValueOrEmptyString(
                              orderData.purchasingForBureauInfo?.id,
                            )}{' '}
                            -{' '}
                            {getValueOrEmptyString(
                              orderData?.purchasingForBureauInfo?.name,
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="order-details__overview-section__content__data">
                        <div className="order-details__overview-section__content__data__title text-bold">
                          Receiving Office/Group
                        </div>
                        <div className="order-details__overview-section__content__data__value">
                          <span
                            className="text-normal"
                            data-testid="receiving-agency-info-office-name"
                          >
                            {getValueOrEmptyString(
                              orderData.purchasingForOfficeInfo?.officeCode,
                            )}{' '}
                            -{' '}
                            {getValueOrEmptyString(
                              orderData.purchasingForOfficeInfo?.officeName,
                            )}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                {orderData?.agencyCode &&
                  !orderData?.purchasingForAgencyCode && (
                    <>
                      <div className="order-details__overview-section__content__data">
                        <div className="order-details__overview-section__content__data__title text-bold">
                          Agency
                        </div>
                        <div className="order-details__overview-section__content__data__value">
                          <span
                            className="text-normal"
                            data-testid="agency-info-agency-name"
                          >
                            {getValueOrEmptyString(orderData.agencyInfo?.id)} -{' '}
                            {getValueOrEmptyString(orderData.agencyInfo?.name)}
                          </span>
                        </div>
                      </div>
                      <div className="order-details__overview-section__content__data">
                        <div className="order-details__overview-section__content__data__title text-bold">
                          Bureau
                        </div>
                        <div className="order-details__overview-section__content__data__value">
                          <span
                            className="text-normal"
                            data-testid="agency-info-bureau-name"
                          >
                            {getValueOrEmptyString(orderData.bureauInfo?.id)} -{' '}
                            {getValueOrEmptyString(orderData?.bureauInfo?.name)}
                          </span>
                        </div>
                      </div>
                      <div className="order-details__overview-section__content__data">
                        <div className="order-details__overview-section__content__data__title text-bold">
                          Office/Group
                        </div>
                        <div className="order-details__overview-section__content__data__value">
                          <span
                            className="text-normal"
                            data-testid="agency-info-office-name"
                          >
                            {getValueOrEmptyString(
                              orderData.officeInfo?.officeCode,
                            )}{' '}
                            -{' '}
                            {getValueOrEmptyString(
                              orderData.officeInfo?.officeName,
                            )}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                <div className="order-details__overview-section__content__data">
                  <div className="order-details__overview-section__content__data__title text-bold">
                    Agency order number
                  </div>
                  <div className="order-details__overview-section__content__data__value">
                    <span
                      className="text-normal"
                      data-testid="agency-info-office-name"
                    >
                      {getValueOrEmptyString(orderData.customerAssignedNumber)}
                    </span>
                  </div>
                </div>
                <div className="order-details__overview-section__content__data">
                  <div className="order-details__overview-section__content__data__title text-bold">
                    Requisition number
                  </div>
                  <div className="order-details__overview-section__content__data__value">
                    <span
                      className="text-normal"
                      data-testid="agency-info-office-name"
                    >
                      {orderData.requisitionNumber}
                    </span>
                  </div>
                </div>
                <div className="order-details__overview-section__content__data">
                  <div className="order-details__overview-section__content__data__title text-bold">
                    Agency financial data
                  </div>
                  <div className="order-details__overview-section__content__data__value">
                    <span
                      className="text-normal"
                      data-testid="agency-info-office-name"
                    >
                      {getValueOrEmptyString(orderData.finAdditionalInfo)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="order-details__overview-section__content">
                <div className="order-details__overview-section__content__data">
                  <div className="order-details__overview-section__content__data__title text-bold">
                    Treasury Account Symbol
                  </div>
                  <div className="order-details__overview-section__content__data__value">
                    <span className="text-normal" data-testid="agency-info-tas">
                      {getValueOrEmptyString(tasData?.validateBOAC?.tas)}
                    </span>
                  </div>
                </div>
                {orderData?.agencyInfo?.isDOD === 1 && (
                  <div className="order-details__overview-section__content__data">
                    <div className="order-details__overview-section__content__data__title text-bold">
                      Transportation Control Number
                    </div>
                    <div className="order-details__overview-section__content__data__value">
                      <span
                        className="text-normal"
                        data-testid="agency-info-tas"
                      >
                        {getValueOrEmptyString(
                          orderData?.transportationControlNumber,
                        )}
                      </span>
                    </div>
                  </div>
                )}

                <div className="order-details__overview-section__content__data">
                  <div className="order-details__overview-section__content__data__title text-bold">
                    Signal Code
                  </div>
                  <div className="order-details__overview-section__content__data__value">
                    <span
                      className="text-normal"
                      data-testid="agency-info-finSignalCode"
                    >
                      {getValueOrEmptyString(orderData.finSignalCode)}
                    </span>
                  </div>
                </div>
                <div className="order-details__overview-section__content__data">
                  <div className="order-details__overview-section__content__data__title text-bold">
                    Service Code
                  </div>
                  <div className="order-details__overview-section__content__data__value">
                    <span
                      className="text-normal"
                      data-testid="agency-info-finServiceCode"
                    >
                      {getValueOrEmptyString(orderData.finServiceCode)}
                    </span>
                  </div>
                </div>
                <div className="order-details__overview-section__content__data">
                  <div className="order-details__overview-section__content__data__title text-bold">
                    Fund Code
                  </div>
                  <div className="order-details__overview-section__content__data__value">
                    <span
                      className="text-normal"
                      data-testid="agency-info-finFundCode"
                    >
                      {getValueOrEmptyString(orderData.finFundCode)}
                    </span>
                  </div>
                </div>
                <div className="order-details__overview-section__content__data">
                  <div className="order-details__overview-section__content__data__title text-bold">
                    Supplementary address
                  </div>
                  <div className="order-details__overview-section__content__data__value">
                    <span
                      className="text-normal"
                      data-testid="agency-info-finSupplementaryAddress"
                    >
                      {getValueOrEmptyString(orderData.finSupplementaryAddress)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/*        <div className="order-details__overview-section__sub-section-container">
          <h4 className="font-sans-xs text-bold order-details__overview-section__sub-section-title">
            FINANCIAL INFORMATION
          </h4>
        </div>
        <div className="order-details__overview-section__content-container">
          <div className="order-details__overview-section__content">
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Billing address
              </div>
              <div className="order-details__overview-section__content__data__value">
                <p
                  className="text-normal"
                  data-testid="agency-info-financial-info-billing-address-1"
                >
                  GSA Accounts Payable Branch
                </p>
                <p
                  className="text-normal"
                  data-testid="agency-info-financial-info-billing-address-2"
                >
                  P.O. Box 419018
                </p>
                <p
                  className="text-normal"
                  data-testid="agency-info-financial-info-billing-city-state-zip"
                >
                  Kansas City, MO 64141
                </p>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Signal code
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="agency-info-financial-info-signal-code"
                >
                  {orderData?.finSignalCode}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Service code
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="agency-info-financial-info-service-code"
                >
                  {orderData?.finServiceCode}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Fund code
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="agency-info-financial-info-fund-code"
                >
                  {orderData?.finFundCode}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Supplementary address
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="agency-info-financial-info-fund-code"
                >
                  {orderData?.finSupplementaryAddress}
                </span>
              </div>
            </div>
          </div>
          <div className="order-details__overview-section__content">
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Additional funds per unit for AREQs
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="agency-info-financial-info-additional-funds-per-unit"
                >
                  {formatCurrency(orderData?.finAdditionalFundsPerUnit)}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Total unit price with AREQs
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="agency-info-financial-info-total-unit-price"
                >
                  {formatCurrency(
                    orderData?.finAdditionalFundsPerUnit +
                      orderData?.unitPriceToGsa,
                  )}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Agency financial data
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="agency-info-financial-info-additional-info"
                >
                  {orderData?.finAdditionalInfo}
                </span>
              </div>
            </div>
          </div>
        </div> */}
          </div>
          <h3 className="font-sans-lg text-bold order-details__overview-title">
            Delivery and mailing
          </h3>
          <div className="order-details__overview-section">
            <OrderAddress
              title={getDeliveryAddressTitle()}
              entityName={deliveryAddress?.entityName}
              stateCode={deliveryAddress?.stateCode}
              countryCode={getAddressCountryCode(deliveryAddress)}
              firstName={deliveryContact?.firstName}
              lastName={deliveryContact?.lastName}
              testId="order-details-delivery-address"
              phoneNumber={deliveryContact?.phoneNumber}
              email={deliveryContact?.email}
              zipcode={deliveryAddress?.zipcode}
              line1={deliveryAddress?.addressLine1}
              line2={deliveryAddress?.addressLine2}
              city={deliveryAddress?.city}
            />
            <OrderAddress
              title="REQUISITIONING ADDRESS"
              entityName={requisitionerAddress?.entityName}
              stateCode={requisitionerAddress?.stateCode}
              countryCode={getAddressCountryCode(requisitionerAddress)}
              firstName={requisitionerContact?.firstName}
              lastName={requisitionerContact?.lastName}
              testId="order-details-requisitioning-address"
              phoneNumber={requisitionerContact?.phoneNumber}
              email={requisitionerContact?.email}
              zipcode={requisitionerAddress?.zipcode}
              line1={requisitionerAddress?.addressLine1}
              line2={requisitionerAddress?.addressLine2}
              city={requisitionerAddress?.city}
              state={requisitionerAddress?.state}
            />
            <OrderAddress
              title="MAILING ADDRESS"
              entityName={mailingAddress?.entityName}
              stateCode={mailingAddress?.stateCode}
              countryCode={getAddressCountryCode(mailingAddress)}
              firstName={mailingContact?.firstName}
              lastName={mailingContact?.lastName}
              testId="order-details-mailing-address"
              phoneNumber={mailingContact?.phoneNumber}
              email={mailingContact?.email}
              zipcode={mailingAddress?.zipcode}
              line1={mailingAddress?.addressLine1}
              line2={mailingAddress?.addressLine2}
              city={mailingAddress?.city}
            />
            {deliveryDealershipAddress && (
              <OrderAddress
                title="DEALERSHIP DELIVERY ADDRESS"
                entityName={deliveryDealershipAddress?.dealerName}
                stateCode={deliveryDealershipAddress?.state?.stateCode}
                countryCode={getAddressCountryCode(deliveryAddress)}
                firstName=""
                lastName=""
                testId="order-details-dealership-delivery-address"
                phoneNumber={deliveryDealershipAddress?.phone}
                email={deliveryDealershipAddress?.email}
                zipcode={deliveryDealershipAddress?.postalCode}
                line1={deliveryDealershipAddress?.address1}
                line2={deliveryDealershipAddress?.address2}
                city={deliveryDealershipAddress?.city}
                displayPointOfContact={false}
              />
            )}
            <OrderAddress
              title={getDeliveryAddressTitle()}
              entityName={deliveryAddress?.entityName}
              stateCode={deliveryAddress?.stateCode}
              countryCode={getAddressCountryCode(deliveryAddress)}
              firstName={deliveryContact?.firstName}
              lastName={deliveryContact?.lastName}
              testId="order-details-delivery-address"
              phoneNumber={deliveryContact?.phoneNumber}
              email={deliveryContact?.email}
              zipcode={deliveryAddress?.zipcode}
              line1={deliveryAddress?.addressLine1}
              line2={deliveryAddress?.addressLine2}
              city={deliveryAddress?.city}
            />
          </div>
        </>
      )}
      <h3 className="font-sans-lg text-bold order-details__overview-title">
        Contract information
      </h3>
      <div className="order-details__overview-section">
        <div className="order-details__overview-section__content-container">
          <div className="order-details__overview-section__content">
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Contract number
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-contract-details-contract-number"
                >
                  {getValueOrEmptyString(contractNumber)}
                </span>
              </div>
            </div>
            {/* <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Modification
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-contract-details-modification-number"
                >
                  {getValueOrEmptyString(contractVersion)}
                </span>
              </div>
            </div> */}
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                uPIID contract number
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-contract-details-upiid-number"
                >
                  {getValueOrEmptyString(contractMod?.contract?.contractUpiid)}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Schedule line
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-contract-details-schedule-line"
                >
                  {getValueOrEmptyString(scheduleLine)}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Solicitation number
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-contract-details-schedule-line"
                >
                  {getValueOrEmptyString(
                    contractMod?.contract?.solicitationNumber,
                  )}
                </span>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                uPIID soliciation number
              </div>
              <div className="order-details__overview-section__content__data__value">
                <span
                  className="text-normal"
                  data-testid="order-info-contract-details-schedule-line"
                >
                  {getValueOrEmptyString(
                    contractMod?.contract?.solicitationUpiid,
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="order-details__overview-section__content">
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Origin/ Assembly point
                <p className="sub-title">City, State</p>
              </div>

              <div className="order-details__overview-section__content__data__value">
                <p className="text-normal">
                  {getValueOrEmptyString(contractModificationLine?.originCity)},
                  {getValueOrEmptyString(
                    contractModificationLine?.originStateCode,
                  )}
                </p>
              </div>
            </div>
            <div className="order-details__overview-section__content__data">
              <div className="order-details__overview-section__content__data__title text-bold">
                Award date
              </div>
              <div className="order-details__overview-section__content__data__value">
                <p className="text-normal">
                  {getValueOrEmptyString(contractAwardedDateFormatted)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewModificationHistoryModal
        isOpen={viewModificationHistoryModal.isOpen}
        handleClose={viewModificationHistoryModal.closeModal}
        rpnNumber={orderData?.rpnNumber}
        modificationHistory={orderModHistory}
      />
    </div>
  );
};

OrderDetails.propTypes = {
  orderData: PropTypes.objectOf(Object).isRequired,
  contractModificationLine: PropTypes.objectOf(Object).isRequired,
  deliveryDealershipAddress: PropTypes.instanceOf(Object),
  canViewLeasing: PropTypes.bool.isRequired,
  orderModHistory: PropTypes.instanceOf(Object),
};

OrderDetails.defaultProps = {
  deliveryDealershipAddress: null,
  canViewLeasing: false,
  orderModHistory: [],
};

export default OrderDetails;
